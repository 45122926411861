import * as LiveUpdates from "@capacitor/live-updates";
import { shouldBlockReload, shouldReloadApp } from "@/application/utils/localStorage";
import { captureException } from "@sentry/vue";

export const liveUpdateSync = async () => {
  try {
    const result = await LiveUpdates.sync();
    shouldReloadApp(result.activeApplicationPathChanged)
  } catch (error: unknown) {
    captureException(error);
  }
};

export const liveUpdateReload = async () => {
  if (shouldReloadApp() && !shouldBlockReload()) {
    await LiveUpdates.reload();
  } else {
    await liveUpdateSync();
  }
};
