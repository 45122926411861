export type LoginRequestData = {
  email: string,
  password: string
};

export type ConfirmPasswordRequestData = {
  password: string,
};

export type requestAccessTokenChallenge = {
  answer?: string,
  deviceName?: string,
}

export const TwoFactors = {
  Fortitfy: 'fortify',
  Birthdate: 'birthdate',
} as const;

export type requestAccessTokenResponse = {
  twoFactor?: typeof TwoFactors[keyof typeof TwoFactors]
  accessToken?: string,
  clientSecret?: string,
}
