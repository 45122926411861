<template>
  <div
    v-for="depotFond in depotFonds"
    :key="depotFond.fond.isin"
    class="fond py-2.5 mx-2.5"
  >
    <div class="flex flex-row justify-between">
      <div class="text-left">
        {{ depotFond.fond.name }}
        <div class="text-sm text-muted">
          ISIN: <a
          :href="depotFond.fond.url"
          target="_blank"
        >{{ depotFond.fond.isin }}</a>
        </div>
      </div>
      <div class="text-right">
        <b v-text="formatDecimal(depotFond.evaluation)" />
        <div
          v-if="depotFond.deadline !== null"
          class="text-sm text-muted"
        >
          {{ formatDate(depotFond.deadline) }}
        </div>
      </div>
    </div>
    <div class="text-muted">
      {{ formatDecimalIgnoreSuffix(depotFond.proportions) }} Anteile
      {{ calculateAnteilswertDisplay(depotFond) }}<br>
      Sparrate: {{ formatDecimal(depotFond.paymentRate) }} {{ ContractPaymentName[depotFond.paymentType] }}<br>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DepotFond } from '@/api/contracts/types';
import { formatDate } from '@/application/utils/date';
import { formatDecimal, formatDecimalIgnoreSuffix } from '@/application/utils/money';
import { ContractPaymentName } from "../types/types";



interface Props {
  depotFonds: DepotFond[],
}
defineProps<Props>()


function calculateAnteilswertDisplay (depotFond: DepotFond): string
{
  const value = depotFond.proportions && depotFond.proportions !== 0
    ? depotFond.evaluation / depotFond.proportions
    : 0

  return `(Anteilswert ${formatDecimal(value)})`
}

</script>

<style lang="scss" scoped>
.fond {
  border-bottom: 1px solid #e7e7e7;
  cursor: default;

  &:last-child {
    border-bottom: 0;
  }
}
</style>
