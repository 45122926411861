<template>
  <div class="mb-14">
    <!--  BERUF  -->
    <section class="w-full flex justify-center mb-14">
      <div class="flex flex-col gap-5 w-full sm:w-1/2 md:w-1/3">
        <DsFormGroup
          label="Beruf"
          id="formError.beruf"
        >
          <DsInput
            v-model="form.beruf"
            size="lg"
          />
          <DsFieldError
            v-for="error in formErrors.beruf"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>

        <DsFormGroup
          :label="`${index === 1 ? '': `${index}. `}Berufsstatus`"
          v-for="index in berufStatusCount"
          :key="index"
          id="formError.taetigkeitsstatus"
        >
          <div class="flex flex-row justify-between items-center gap-2">
            <DsSelect
              :model-value="form.taetigkeitsstatus[index - 1]"
              @update:model-value="(value) => setTaetigkeit(index - 1, value)"
              :data="taetigkeitsstatus"
              placeholder=""
              size="lg"
              required
              class="w-full"
            />
            <DsButton
              v-if="index > 1 && index >= form.taetigkeitsstatus.length"
              title="Berufstatus entfernen"
              @click="removeBerufStatusField(index)"
              size="lg"
              variant="secondary"
              icon="minus"
            />
            <DsButton
              v-if="index === berufStatusCount && berufStatusCount < 3 && index === form.taetigkeitsstatus.length"
              title="Weiteren Berufstatus hinzüfugen"
              @click="addNewBerufStatusField"
              size="lg"
              variant="secondary"
              icon="plus"
            />
          </div>
          <DsFieldError
            v-if="formErrors.taetigkeitsstatus?.[index - 1] ?? false"
          >
            {{ formErrors.taetigkeitsstatus[index - 1] }}
          </DsFieldError>
        </DsFormGroup>
      </div>
    </section>

    <!-- EINKOMMENSDATEN -->
    <section class="flex flex-col items-center mb-14">
      <h2 class="checkup-h2">
        Mein Einkommen beträgt
      </h2>
      <DsAlert
        type="info"
        class="text-left max-w-lg mt-2 mb-6"
        label="Hinweis"
      >
        Falls das Brutto- oder Nettoeinkommen nicht bekannt ist, können Sie auch nur einen der beiden Werte
        eintragen.
      </DsAlert>

      <div class="w-full sm:w-1/2 md:w-1/3 mb-5">
        <DsFormGroup label="Bruttoeinkommen">
          <MoneyInput
            :model-value="form.brutto"
            size="lg"
            as-string
            @update:model-value="set('brutto', $event)"
          />
          <DsFieldError
            v-for="error in formErrors.brutto"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
      </div>
      <div class="w-full sm:w-1/2 md:w-1/3 mb-7">
        <DsRadioGroup
          :model-value="form.isBruttoJahresGehalt ? '1' : '0'"
          :variant="isMobile ? 'button-col' : 'button'"
          class="w-full bg-white"
          @update:model-value="($event) => form.isBruttoJahresGehalt = $event === '1'"
        >
          <DsRadioButton
            value="1"
            style="height: 40px"
          >
            im Jahr
          </DsRadioButton>

          <DsRadioButton
            value="0"
            style="height: 40px"
          >
            im Monat
          </DsRadioButton>
        </DsRadioGroup>
      </div>

      <div class="w-full sm:w-1/2 md:w-1/3 mb-5">
        <DsFormGroup label="Nettoeinkommen">
          <MoneyInput
            :model-value="form.netto"
            size="lg"
            as-string
            @update:model-value="set('netto', $event)"
          />
          <DsFieldError
            v-for="error in formErrors.netto"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
      </div>

      <div class="w-full sm:w-1/2 md:w-1/3">
        <DsRadioGroup
          :model-value="form.isNettoJahresGehalt ? '1' : '0'"
          :variant="isMobile ? 'button-col' : 'button'"
          class="w-full bg-white"
          @update:model-value="set('isNettoJahresGehalt', $event === '1')"
        >
          <DsRadioButton
            value="1"
            style="height: 40px"
          >
            im Jahr
          </DsRadioButton>

          <DsRadioButton
            value="0"
            style="height: 40px"
          >
            im Monat
          </DsRadioButton>
        </DsRadioGroup>
      </div>
    </section>

    <!-- KRANKENVERSICHERUNGSSTATUS -->
    <section class="flex flex-col items-center">
      <div class="flex flex-col items-center justify-center">
        <h2 class="mb-3 md:mb-5 checkup-h2">
          Mein Krankenversicherungsstatus:
        </h2>
        <DsFieldError
          v-for="error in formErrors.krankenversicherungsstatus"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </div>

      <div class="w-full flex justify-center">
        <DsRadioGroup
          :model-value="`${form.krankenversicherungsstatus}`"
          :variant="isMobile ? 'button-col' : 'button'"
          class="w-full lg:w-2/3 bg-white"
          @update:model-value="form.krankenversicherungsstatus = Number($event)"
        >
          <DsRadioButton
            v-for="{ label, value } in krankenversicherungsstatus"
            :key="value"
            :value="`${value}`"
            style="height: 40px"
          >
            {{ label }}
          </DsRadioButton>
        </DsRadioGroup>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import {
  DsAlert,
  DsButton,
  DsFieldError,
  DsFormGroup,
  DsInput,
  DsRadioButton,
  DsRadioGroup,
  DsSelect,
} from '@demvsystems/design-components';
import { sortBy } from 'lodash-es';

import { useDetectMobile } from '@/application/composables/detectMobile';
import { toLabelAndValue } from '@/checkup/utils/demvWerte';
import { demvWerte, useStep } from '@/checkup/state';
import { Step } from '@/checkup/utils/steps';
import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import { onMounted, ref } from 'vue';

const { isMobile } = useDetectMobile();
const { form, formErrors, set } = useStep(Step.BERUF);

const berufStatusCount = ref(1);

const taetigkeitsstatus = sortBy(
  toLabelAndValue(demvWerte.taetigkeitstatus),
  'label',
);
const krankenversicherungsstatus = toLabelAndValue(
  demvWerte.krankenversicherungsstatus,
);

onMounted(() => {
  let length = form.value.taetigkeitsstatus.length
  // when empty, we have to set initial value
  if (length === 0) {
    length++;
  }
  berufStatusCount.value = length
})
const addNewBerufStatusField = () => {
  berufStatusCount.value++;
};

const removeBerufStatusField = (index: number) => {
  form.value.taetigkeitsstatus.splice(index - 1, 1)
  berufStatusCount.value--;
};

const setTaetigkeit = (index, value) => {
  (form.value.taetigkeitsstatus ?? []).splice(index, 1, value);
};
</script>
