<template>
  <section class="section pt-0">
    <div class="container">
      <div class="text-center mb-7">
        <h2>Wie finde ich den geeigneten Tarif für meine Altersvorsorge?</h2>
      </div>
      <div class="row pb-7 text-center">
        <div class="col-12">
          <p class="mb-0">
            Es gibt zahlreiche Tarife für die Altersvorsorge und
            häufig bieten Produktgeber mehrere Varianten an.
          </p>
          <p class="mb-0">
            Je nach Gestaltung (Typ) der Anlage, ergibt sich
            daraus eine unterschiedliche Renditechance.
          </p>
        </div>
      </div>
    </div>

    <TarifQuiz
      :tarif-garantie="tarifGarantie"
      horizontal
      @handleScrolling="handleScrolling"
    />

    <StoreAltersvorsorge />

    <div class="container">
      <TarifChart
        :tarif-names="idxNameMapping"
        class="mb-5"
        @handleScrolling="handleScrolling"
      />
      <div class="flex flex-col w-full gap-8">
        <AccordionContainer
          v-for="(entry, idx) in accordionData"
          :key="idx"
          :entries="entry.entries"
          :name="entry.name"
          :visible-accordions="visibleAccordions"
          @toggle-accordion="toggleAccordion"
         />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, onBeforeUnmount } from 'vue';
import StoreAltersvorsorge from '@/bedarf/components/altersvorsorge/altersvorsorge/StoreAltersvorsorge.vue';

import TarifQuiz from '../quiz/TarifQuiz.vue';
import AccordionContainer from './AccordionContainer.vue';
import TarifChart from './TarifChart.vue';
import tarifData from './tarifData.json';
import type { AccordionData, TableEntry } from "@/bedarf/components/altersvorsorge/altersvorsorge/finder/types";
import { scrollToId } from "@/application/utils/scrollToId";

interface Props {
  tarifGarantie: number | null,
}

withDefaults(defineProps<Props>(), {
  tarifGarantie: null
})

const idxNameMapping = {
  1: 'Klassik Rente neu',
  2: 'Hybrid Rente alt',
  3: 'Hybrid Rente neu',
  4: 'Index-Hybrid-Rente',
  5: 'Garantiefonds-Rente',
  6: 'Interne dynamische Hybrid Rente',
  7: 'Externe dynamische Hybrid Rente',
  8: 'Bank-Garantierente',
  9: 'With-Profit-Rente',
  10: 'Variable Annuity',
  11: 'Fondsgebundene Rente ohne Garantie',
};

const accordionData: AccordionData[] = [
  {
    name: 'Wachstum',
    entries: [
      {
        name: idxNameMapping[1],
        headline: 'Zeitpunktgarantie:',
        text: 'Die Beiträge und das Kapital werden im staatlich, streng kontrollierten\n'
          + 'Sicherungsvermögen angelegt. Der Vertrag erhält die jährlich erzielten\n'
          + 'Überschüsse. Es wird ein Mindestkapital zum Rentenbeginn garantiert\n'
          + 'und Mindest-Rechnungsgrundlagen für die Kalkulation der späteren\n'
          + 'Rentenzahlung.',
        img: 'bedarfsinfo/altersvorsorge/klassik-rente-neu',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[1]),
      }, {
        name: idxNameMapping[2],
        headline: 'Minimales Fondsinvestment:',
        text: 'Die Beiträge und das Kapital werden im staatlich, streng kontrollierten\n'
          + 'Sicherungsvermögen angelegt. Der Vertrag erhält die jährlich erzielten\n'
          + 'Überschüsse, die dann in einem vom Kunden gewählten Publikumsfonds\n'
          + 'angelegt werden. Die Fondsanlage ist sehr klein und wächst\n'
          + 'sehr langsam. Entfallen die Überschüsse, erfolgen keine Einzahlungen\n'
          + 'mehr in die Fondsanlage.',
        img: 'bedarfsinfo/altersvorsorge/hybrid-rente-alt',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[2]),
      }, {
        name: idxNameMapping[3],
        headline: 'Zwei unabhängige Anlagen:',
        text: 'Die Beiträge werden aufgeteilt und damit zwei getrennte Anlagen angespart.\n'
          + 'Mit einem Teil der Beiträge wird die Mindestgarantie zum\n'
          + 'Rentenbeginn im klassischen Sicherungsvermögen angespart. Je nach\n'
          + 'Ertragskraft des Sicherungsvermögens und Vertragsdauer, werden\n'
          + 'deutlich mehr als 50 % der Beiträge benötigt. Die übrigen Beiträge\n'
          + 'werden in einem vom Kunden gewählten Publikumsfonds investiert.\n'
          + 'Abweichend gibt es Versicherer, die auch einen „internen Fonds“ (=Sondervermögen)\n'
          + 'als zweite Anlage anbieten. Das Sondervermögen\n'
          + 'wird vom Versicherer zusammengestellt. Anders als bei einem Publikumsfonds\n'
          + ', hat der Kunde keinen konkreten Einblick in die Anlage, das\n'
          + 'Management und die Kosten. Es bestehen keine detaillierten\n'
          + 'Auskunftspflichten oder –rechte. Der Kunde muss dem Anbieter und\n'
          + 'seinen Werbeaussagen vertrauen.',
        img: 'bedarfsinfo/altersvorsorge/hybrid-rente-neu',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[3]),
      }, {
        name: idxNameMapping[4],
        headline: 'Anlage mit Garantiezertifikat:',
        text: 'Die Beiträge und das Kapital werden im staatlich, streng\n'
          + 'kontrollierten Sicherungsvermögen angelegt.\n'
          + 'Der Vertrag erhält die jährlich erzielten Überschüsse,\n'
          + 'welche in ein Garantiezertifikat investiert werden.\n'
          + 'Soweit das Garantiezertifikat Erträge erzielt, fließen\n'
          + 'diese in das Sicherungsvermögen und sind dort durch\n'
          + 'die besonderen Anlagevorschriften bis zum Laufzeitende\n'
          + 'gesichert (LockIn). Es wird ein Mindestkapital zum\n'
          + 'Rentenbeginn garantiert und Mindest-Rechnungsgrund-\n'
          + 'lagen für die Kalkulation der späteren Rentenzahlung.',
        img: 'bedarfsinfo/altersvorsorge/index-hybrid-rente',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[4]),
      },
    ],
  }, {
    name: 'Rendite',
    entries: [
      {
        name: idxNameMapping[5],
        headline: '',
        text: 'Die Beiträge und das Kapital werden in einem Garantiefonds angelegt\n'
          + 'und liegen außerhalb des Einflussbereichs des Versicherers. Die\n'
          + 'Kapitalanlagegesellschaft mischt Aktien- und Rentenanlage laufzeitabhängig,\n'
          + 'um eine Mindestgarantie bis zum Laufzeitende des Garantiefonds\n'
          + 'zu erzielen. Der Versicherer garantiert Mindest-Rechnungsgrundlagen\n'
          + 'für die Kalkulation der späteren Rentenzahlung. Die Kapitalanlagegesellschaft\n'
          + 'kann den Fonds aus wirtschaftlichen Gründen\n'
          + 'vorzeitig beenden und auflösen. Das Vermögen aus dem Garantiefonds\n'
          + 'wird in einen Publikumsfonds ohne Garantie umgeschichtet.\n'
          + 'Der Versicherer kann hierfür einen Fonds bestimmen, wenn der\n'
          + 'Kunde keine eigene Wahl (aus dem Portfolio des Versicherers) trifft.\n'
          + 'Es werden weiterhin Beiträge geleistet, aber in den neuen Publikumsfonds.\n'
          + 'Ab diesem Zeitpunkt besteht weder eine Garantie noch\n'
          + 'ein Anspruch auf einen gleichwertigen Garantiefonds, insbesondere\n'
          + 'weil keiner angeboten wird. In der bAV trifft den Arbeitgeber in\n'
          + 'dieser Situation ein großes Haftungsrisiko.',
        img: 'bedarfsinfo/altersvorsorge/garantiefonds-rente',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[5]),
      }, {
        name: idxNameMapping[6],
        headline: 'Zeitpunktgarantie:',
        text: 'Die Beiträge und das Kapital werden im staatlich, streng kontrollierten\n'
          + 'Sicherungsvermögen angelegt. Der Vertrag erhält die jährlich\n'
          + 'erzielten Überschüsse. Es wird ein Mindestkapital zum Rentenbeginn\n'
          + 'garantiert und Mindest-Rechnungsgrundlagen für die Kalkulation der späteren Rentenzahlung.',
        img: 'bedarfsinfo/altersvorsorge/interne-dynamische-hybrid-rente',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[6]),
      }, {
        name: idxNameMapping[7],
        headline: 'Minimales Fondsinvestment:',
        text: 'Die Beiträge und das Kapital werden im staatlich,\n'
          + 'streng kontrollierten Sicherungsvermögen angelegt.\n'
          + 'Der Vertrag erhält die jährlich erzielten Überschüsse,\n'
          + 'die dann in einem vom Kunden gewählten Publikums'
          + 'fonds angelegt werden. Die Fondsanlage ist sehr klein\n'
          + 'und wächst sehr langsam. Entfallen die Überschüsse,\n'
          + 'erfolgen keine Einzahlungen mehr in die Fondsanlage.',
        img: 'bedarfsinfo/altersvorsorge/externe-dynamische-hybrid-rente',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[7]),
      },
    ],
  }, {
    name: 'Chance',
    entries: [
      {
        name: idxNameMapping[8],
        headline: 'Versicherungsmantel ohne Versicherer-Garantie:',
        text: 'Die Beiträge des Kunden werden ausschließlich in ein Garantiezertifikat\n'
          + 'oder einen Wertsicherungsfonds einer externen Kapitalanlagegesellschaft\n'
          + 'investiert. Der Versicherer garantiert nur die Rechnungsgrundlagen\n'
          + 'für die Kalkulation der späteren Rentenzahlung. Das vertraglich\n'
          + 'prognostizierte Mindestkapital zum Rentenbeginn wird durch\n'
          + 'die externe Kapitalanlage erwirtschaftet. Ein unmittelbarer Rechtsanspruch\n'
          + 'besteht nur in Bezug auf die Auszahlung aus der externen\n'
          + 'Anlage. Abweichend kann bei einem Produkt eine Versicherer-Garantie\n'
          + 'gegen Zusatzbeitrag hinzugebucht werden.',
        img: 'bedarfsinfo/altersvorsorge/bank-garantierente',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[8]),
      }, {
        name: idxNameMapping[9],
        headline: 'Angelsächsische Rente:',
        text: 'Die Produkte stammen aus Großbritannien, Irland\n'
          + 'oder Kanada und legen in einem „internen Fonds“\n'
          + '(= Sondervermögen) des Versicherers an.\n'
          + 'Das angelsächsische Aufsichtsrecht erlaubt eine\n'
          + 'höhere Aktienanlage (bis zu 80%). Entsprechend\n'
          + 'ist die Garantie meist anders aufgebaut als in\n'
          + 'Deutschland (z. B. Anteilspreisgarantie, Bonus).\n'
          + 'Beitragsunterbrechung und anderes Kundenverhalten\n'
          + 'kann zum Verlust der Garantie führen.',
        img: 'bedarfsinfo/altersvorsorge/with-profit-rente',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[9]),
      }, {
        name: idxNameMapping[10],
        headline: 'Versicherungsmantel mit Derivate-Sicherung:',
        text: 'Die Beiträge werden in vom Kunden gewählte Publikumsfonds\n'
          + 'oder „interne Fonds“ (= Sondervermögen) des Versicherers investiert.\n'
          + 'Ergänzend sichert der Versicherer mögliche Verluste\n'
          + 'der Publikumsfonds durch den regelmäßigen Kauf von Derivaten\n'
          + 'ab. Für diese Absicherung über den Kapitalmarkt entstehen\n'
          + 'Kosten, die meist gesondert abgerechnet werden. Der Versicherer\n'
          + 'garantiert ein Mindestkapital zum Laufzeitende sowie\n'
          + 'Mindest-Rechnungsgrundlagen für die Kalkulation der späteren\n'
          + 'Rentenzahlung. Diese Rentenversicherungen stammen aus dem\n'
          + 'europäischen Ausland, z. B. Luxemburg oder Irland und unterliegen\n'
          + 'weder dem deutschen Aufsichtsrecht noch gibt es\n'
          + 'Schutz durch den deutschen Sicherungsfonds Protektor.',
        img: 'bedarfsinfo/altersvorsorge/variable-annuity',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[10]),
      }, {
        name: idxNameMapping[11],
        headline: 'Individuelle Fondsanlage im Versicherungsmantel:',
        text: 'Die Beiträge fließen in bis zu 10 vom Kunden gewählte Publikumsfonds.\n'
          + 'Kunden steuern die Anlage ganz individuell selbst. Der Kunde\n'
          + 'kann die Fonds wechseln, in die er spart (switch), aber auch in denen\n'
          + 'das Kapital (shift ) angelegt ist. Vorteilhaft sind Produkte mit einer\n'
          + 'großen Auswahl qualitativ guter Fonds in verschiedenen Anlageklassen,\n'
          + 'kostenfreiem Fondswechsel, Sicherungsoptionen und Ablaufmanagements.\n'
          + 'Der Versicherer garantiert lediglich Mindest-Rechnungsgrundlagen\n'
          + 'für die Kalkulation der späteren Rentenzahlung.',
        img: 'bedarfsinfo/altersvorsorge/fondsgebundene-rente-ohne-garantie',
        imgAlt: '',
        tableEntries: getTableDataByType(idxNameMapping[11]),
      },
    ],
  },
];

const visibleAccordions = ref<string[]>([])
const timer = ref<null | ReturnType<typeof setTimeout>>(null)

function handleScrolling(elementId: string) {
  hideAllExcept(elementId);
  timer.value = setTimeout(() => {
    scrollToId(elementId);
  }, 400);
}

function hideAllExcept(name: string) {
  visibleAccordions.value = [];
  visibleAccordions.value.push(name);
}

function getTableDataByType(type: string): TableEntry[] {
  return tarifData.filter((data) => data.typ === type);
}

function toggleAccordion(name: string) {
  const index = visibleAccordions.value.indexOf(name);

  if (index > -1) {
    visibleAccordions.value.splice(index);
  } else {
    visibleAccordions.value.push(name);
  }
}

onBeforeUnmount(() => {
  if (timer.value === null) {
    return;
  }

  clearTimeout(timer.value);
});
</script>
