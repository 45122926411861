<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import ApiContainer from '@/application/components/ApiContainer.vue';
import CardGrid from '@/contracts/components/CardGrid.vue';
import DocumentCard from '@/documents/components/DocumentCard.vue';
import { useContractStore } from '@/contracts/stores/contractStore';
import { useDocumentStore } from '@/documents/store/documentStore';
import { UnreadHintType } from '@/api/unreadHint/types';
import { useUnreadHintStore } from '@/application/store/unreadHintStore';
import { useMediaPreviewStore } from "@/application/store/mediaPreviewStore";
import { TrackEventCategory } from '@/application/utils/matomo/events';
import { useTrackSearch } from "@/application/composables/useTrackSearch";
import { useBaseDataStore } from "@/user/store/baseDataStore";
import { storeToRefs } from "pinia";
import type { Document } from "@/api/documents/types";
import CardCollectionHeader from "@/application/components/CardCollectionHeader.vue";
import { useSearchFilter } from "@/application/filter/use/useSearchFilter";
import { useFilters } from "@/application/filter/use/useFilters";
import DocumentsMeta from '@/documents/components/DocumentsMeta.vue';

defineEmits(['openModal']);

const { isCompany } = useBaseDataStore();
const { trackSearch } = useTrackSearch(`isCompany: ${isCompany}`, TrackEventCategory.DOCUMENT_SEARCH)
const { setMediaRef } = useMediaPreviewStore();
const { loading, documents, error } = storeToRefs(useDocumentStore());
const { fetchDocuments } = useDocumentStore();

const { filter: searchFilter, query } = useSearchFilter<Document>([
  (doc) => doc.type?.name,
  (doc) => doc.company?.name,
  (doc) => doc.contract?.name,
  (doc) => doc.product?.name,
  (doc) => doc.name,
]);
const { filtered } = useFilters<Document>(documents, [], [
  { active: ref(true), filter: searchFilter }
]);

const { menuHints } = storeToRefs(useUnreadHintStore());
const unreadDocumentHints = computed(() => menuHints.value[UnreadHintType.Document]);
const hasData = computed(() => documents.value.length > 0);
const documentsWithContractsCount = computed(() => {
  return documents.value.filter(doc => doc.contract !== null).length
})

onMounted(async () => {
  await fetchDocuments();
  await useContractStore().fetchContractsAndDepots();
})

</script>

<template>
  <div class="flex flex-col flex-grow mb-10">
    <CardCollectionHeader

      v-model="query"
      v-if="!loading && !error"
      :showContextInfo="documents.length > 0"

      createNewButtonLabel="Dokument hochladen"

      :searchEnabled="documents.length > 0"
      searchPlaceholder="Dokumente durchsuchen..."

      :unreadHintsCount="unreadDocumentHints"
      :unreadHintsType="UnreadHintType.Document"
      unreadHintsLabel="Neue Dokumente als gesehen markieren"

      @searchFocus="trackSearch('focus')"
      @openCreateModal="$emit('openModal')"
    >
      <template #left>
        <DocumentsMeta
          :has-documents="documents.length > 0"
          :documents-count="documents.length"
          :documents-with-contracts-count="documentsWithContractsCount"
        />
      </template>
    </CardCollectionHeader>


    <div
      v-if="!loading && !error"
      class="lg:hidden container"
    >
      <DocumentsMeta
        :has-documents="documents.length > 0"
        :documents-count="documents.length"
        :documents-with-contracts-count="documentsWithContractsCount"
      />
    </div>

    <section class="flex-grow py-4 md:py-8 md:py-7 bg-light">
      <ApiContainer
        :error="error ?? undefined"
        :loading="loading"
        :has-data="hasData"
        refresh-label="Dokumente erneut laden"
        class="container"
        @refresh="() => fetchDocuments()"
      >
        <template #loading>
          Dokumente werden geladen...
        </template>

        <template #default>
          <div class="col-12 flex flex-col items-center">
            <p class="pt-14 pb-5 text-center lead text-muted">
              Es liegen zur Zeit keine Dokumente von Ihnen vor.
            </p>
          </div>
        </template>

        <template #success>
          <CardGrid>
            <template v-for="document in filtered" :key="document.id">
              <div class="flex flex-col">
                <DocumentCard
                  class="cursor-pointer"
                  :document="document"
                  @click="setMediaRef(document.urls.download, `${document.name}.${document.extension}`)"
                />
              </div>
            </template>

          </CardGrid>
        </template>
      </ApiContainer>
    </section>
  </div>
</template>
