<template>
  <section class="flex flex-col mb-14">
    <div class="flex flex-col items-center mb-2">
      <h2 class="checkup-h2">
        Ich bin
      </h2>
      <DsFieldError
        v-for="error in formErrors.anrede"
        :key="error"
      >
        {{ error }}
      </DsFieldError>
    </div>

    <OptionGroup
      has-icons
      :options="[
        {value: Anrede.FRAU, label: 'weiblich', icon: 'icomoon/female',
         iconClass: 'gender'},
        {value: Anrede.HERR, label: 'männlich', icon: 'icomoon/male',
         iconClass: 'gender'},
      ]"
      :value="form.anrede"
      option-width-classes="w-1/3 sm:w-1/4 md:w-1/6"
      @change="set('anrede', $event)"
    />

    <div class="flex flex-col items-center gap-5">
      <!-- VORNAME -->
      <div class="w-full sm:w-1/2 md:w-1/3">
        <DsFormGroup
          label="Vorname"
          id="formError.vorname"
        >
          <DsInput
            :model-value="form.vorname"
            size="lg"
            @update:model-value="set('vorname', $event)"
          />
          <DsFieldError
            v-for="error in formErrors.vorname"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
      </div>

      <!-- Nachname -->
      <div class="w-full sm:w-1/2 md:w-1/3">
        <DsFormGroup
          label="Nachname"
          id="formError.nachname"
        >
          <DsInput
            v-model="form.nachname"
            @update:model-value="() => clear('nachname')"
            size="lg"
          />
          <DsFieldError
            v-for="error in formErrors.nachname"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
      </div>

      <!-- FAMILIENSTAND -->
      <div class="w-full sm:w-1/2 md:w-1/3 mt-8">
        <h2 class="mb-5 checkup-h2">
          Mein Familienstand lautet
        </h2>
        <DsSelect
          v-model="form.familienstand"
          id="formError.familienstand"
          :data="familienstaende"
          placeholder=""
          @update:model-value="() => clear('familienstand')"
          size="lg"
        />
        <DsFieldError
          v-for="error in formErrors.familienstand"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { DsFieldError,
  DsFormGroup,
  DsInput,
  DsSelect } from '@demvsystems/design-components';
import { sortBy } from 'lodash-es';

import OptionGroup from '@/checkup/components/OptionGroup.vue';
import { Anrede, toLabelAndValue } from '@/checkup/utils/demvWerte';
import { demvWerte, useStep } from '@/checkup/state';
import { Step } from '@/checkup/utils/steps';

const { form, formErrors, set, clear } = useStep(Step.GRUNDDATEN);

const familienstaende = sortBy(toLabelAndValue(demvWerte.familienstaende), 'label');
</script>
