<template>
  <section class="section">
    <div class="container">
      <div class="text-center">
        <RouterLink :to="{ name: Finanzmanager.HINWEISE }">
          Zurück zur Übersicht
        </RouterLink>
      </div>
      <div class="mt-2.5">
        <h1 v-text="news.title" />
      </div>
      <div v-text="news.createdAt" />
      <div class="row my-5">
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="col"
          v-html="markdown"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { marked } from 'marked';
import { computed, onMounted } from 'vue';

import type { News } from '@/api/news/types';
import { Finanzmanager } from '@/application/router/types/types';
import { useUnreadHintStore } from "@/application/store/unreadHintStore";
import { UnreadHintType } from "@/api/unreadHint/types";

interface Props {
  news: News,
  content: string,
}

const props = defineProps<Props>();

const markdown = computed(() => marked.parse(props.content));

onMounted(() => {
  useUnreadHintStore().removeUnreadHints([{ type: UnreadHintType.News, object_id: props.news.id }], true);
})
</script>
