import type { UnwrapNestedRefs } from 'vue';
import { computed, reactive, ref, toRaw } from 'vue';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFormHelper<FormDataType extends Record<string, unknown>>(formFields: FormDataType) {
  const submitted = ref(false);
  const loading = ref(false);

  const form = reactive<FormDataType>({ ...formFields });
  const formKeys = Object.keys(formFields) as Array<keyof FormDataType>;

  // get the keys of form and assign the type
  type FormErrorsType = Record<keyof FormDataType, string[]>;
  const defaultFormErrors: FormErrorsType = {} as FormErrorsType;
  formKeys.forEach((formKey) => {
    defaultFormErrors[formKey] = [];
  });

  const formErrors = reactive<FormErrorsType>(
    { ...defaultFormErrors },
  );
  const initialFormData = { ...formFields }

  function updateFormFieldsFromForm (): void {
    formKeys.forEach(key => {
      const formValue = form[key as keyof typeof form];
      initialFormData[key as keyof typeof initialFormData] = formValue as FormDataType[keyof FormDataType]
    })
  }

  /**
   * Indicates if the form was mutated
   */
  const wasMutated = computed(() => formKeys.some((key) => {
    const formValue = form[key as keyof typeof form];
    const initialValue = initialFormData[key as keyof typeof initialFormData];
    return formValue !== initialValue;
  }));

  /**
   * Return an object with all keys / values that have been mutated
   */
  function getMutated(): Partial<FormDataType> {
    const mutatedFields: Partial<FormDataType> = {};
    if (wasMutated.value) {
      formKeys.forEach((key) => {
        const formValue = form[key as keyof UnwrapNestedRefs<FormDataType>];
        const initialValue = initialFormData[key];
        if (formValue !== initialValue) {
          mutatedFields[key] = formValue as FormDataType[keyof FormDataType];
        }
      });
    }
    return mutatedFields;
  }

  /**
   * Computes given key in formErrors has an error
   * @param formKey Key from form
   * @returns ComputedRef<boolean>
   */
  function getError(formKey: keyof FormDataType) {
    return computed(() => toRaw(formErrors)[formKey.toString()].length > 0);
  }

  /**
   * Checks whether the form has any error
   * @returns boolean
   */
  function hasError() {
    const errors = toRaw(formErrors);
    return formKeys.some((formKey) => errors[formKey.toString()].length > 0);
  }

  function assignErrors(errors: Record<keyof FormDataType, string[] | string>) {
    if (typeof errors === 'object') {
      Object.keys(errors).forEach((errorKey: string) => {
        const value = errors[errorKey];
        formErrors[errorKey] = Array.isArray(value) ? value : [value];
      });
    }
  }

  /**
   * sets all values from this helper to its initial state
   */
  function clear() {
    submitted.value = false;
    loading.value = false;

    // reset form values
    Object.assign(form, formFields);

    // reset form errors
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.assign(formErrors, defaultFormErrors);
  }

  function clearSome(keys: string[]) {
    submitted.value = false;
    loading.value = false;

    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      form[key] = initialFormData[key];
      formErrors[key] = [];
    });
  }

  const resetErrors = (keys: string[]) => {
    keys.forEach((key) => {
      delete formErrors[key];
    });
  };

  return {
    submitted,
    loading,
    form,
    formErrors,
    getError,
    hasError,
    clear,
    clearSome,
    assignErrors,
    wasMutated,
    getMutated,
    resetErrors,
    updateFormFieldsFromForm
  };
}
