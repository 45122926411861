<script setup lang="ts">

defineProps<{
  modelValue: boolean
  label: string
  inputName: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', data: boolean): void
}>()

</script>

<template>
  <div class="relative flex gap-3 items-center">
    <input
      @change="(event) => emit('update:modelValue', (event.target as HTMLInputElement).checked)"
      :checked="modelValue"
      :aria-describedby="inputName + ' filter'"
      type="checkbox"
      :id="inputName + ' filter'"
      class="form-checkbox h-5 w-5 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
    >
    <label
      :for="inputName + ' filter'"
      class="text-gray-700 whitespace-nowrap hover:cursor-pointer w-full"
    >
      {{ label }}
    </label>
  </div>
</template>
