<template>
  <ShallowCard>
    <div class="flex items-center">
      <div style="min-width: 1px; flex: 1 1 auto;">
        <CardSparte :sparte="sparte" />
        <CardGesellschaft
          :id="depot.company.id"
          :name="depot.company.name"
          style="margin-top: 13px;"
        />
      </div>
      <div
        style="min-width: 1px; flex: 1 1 125px;"
        class="ml-5"
      >
        <div class="text-right">
          <ContractPayment
            :amount="depot.depot_evaluation"
            bold
          />
        </div>
      </div>
    </div>

    <CardMeta>
      <div
        style="flex: 1 1 100%; min-width: 1px;"
        class="py-1.5 pr-2.5 flex items-center"
      >
        <ContractPayment
          v-if="typeof depot.monthly_payment_rate === 'number'"
          inline
          :amount="depot.monthly_payment_rate ?? null"
        >
          <span class="ml-1 whitespace-nowrap">Monatliche Sparrate</span>
        </ContractPayment>
      </div>
      <div
        v-if="depot.depotFonds !== undefined"
        style="flex: none; min-width: 1px;"
        class="py-1.5 pl-2.5 flex items-center"
      >
        <DsIcon
          name="align-justify"
          fixed-width
          class="text-gray-500 mr-1"
        />
        {{ depot.depotFonds.length }}
        {{ depot.depotFonds.length === 1 ? 'Fond' : 'Fonds' }}
      </div>
    </CardMeta>
  </ShallowCard>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import type { Depot } from '@/api/contracts/types';
import CardGesellschaft from '@/contracts/components/CardGesellschaft.vue';
import CardMeta from '@/contracts/components/CardMeta.vue';
import CardSparte from '@/contracts/components/CardSparte.vue';
import ContractPayment from '@/contracts/components/ContractPayment.vue';
import ShallowCard from '@/contracts/components/ShallowCard.vue';
import { getShortSparteName } from '@/application/utils/sparteFormatter';
import { DsIcon } from '@demvsystems/design-components';

interface Props {
  depot: Depot,
}

const props = defineProps<Props>();
const sparte = computed(() => getShortSparteName(props.depot.product?.name));

</script>
