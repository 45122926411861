<script setup lang="ts">
import { LoginView } from "@/authentication/types/LoginView";
import AuthCardShell from '@/application/components/AuthCardShell.vue';
import { ref, watch } from 'vue';
import TheEmailLoginForm from "@/authentication/components/TheEmailLoginForm.vue";
import ThePasswortLoginForm from "@/authentication/components/ThePasswortLoginForm.vue";
import TwoFactorChallengeForm from "@/authentication/components/TwoFactorChallengeForm.vue";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { Finanzmanager } from "@/application/router/types/types";
import { useUserStore } from "@/user/store/userStore";
import { SecretNames, setSecret } from "@/application/utils/secretStore";
import { DsIcon } from "@demvsystems/design-components";

const router = useRouter();
const route = useRoute();

const view = ref<LoginView>(LoginView.Password);
let shouldRoute = true;
const loading = ref(false);

async function routeToApp() {
  const redirect = route.query?.redirect ?? null;

  return typeof redirect === 'string'
    ? await router.push(decodeURIComponent(redirect))
    : await router.push({ name: Finanzmanager.VERTRAEGE });
}

async function checkAuth() {
  loading.value = true;
  if (await useUserStore().checkAuth()) {
    await useUserStore().onLoggedIn();
    if (shouldRoute) routeToApp();
  }
  loading.value = false;
}

function setrequestAccessToken(requestAccessToken?: string) {
  useUserStore().requestAccessToken = requestAccessToken ?? null;
  view.value = LoginView.TwoFactor;
}

async function loggingIn(accessToken?: string, clientSecret?: string) {
  loading.value = true;
  if (accessToken) await setSecret(SecretNames.AccessToken, accessToken);
  if (clientSecret) await setSecret(SecretNames.ClientSecret, clientSecret);

  await useUserStore().onLoggedIn();
  routeToApp();
}

watch(() => route.query.requestAccessToken,
  (token: unknown) => {
    if (token) {
      shouldRoute = false;
      loading.value = false;
      setrequestAccessToken(token as string)
    } else {
      shouldRoute = true;
      checkAuth();
    }
  },
  { immediate: true },
);

onBeforeRouteUpdate(() => {
  shouldRoute = true;
  view.value = LoginView.Password;
  checkAuth();
})
</script>

<template>
  <AuthCardShell>
    <div class="relative text-center" v-if="loading">
      <DsIcon class="mr-2 text-blue-700" name="spinner-third" variant="duotone" spin size="2x"/>
    </div>

    <TheEmailLoginForm
      v-else-if="view === LoginView.Email"
      @switch-to-password="view = LoginView.Password"
    />

    <ThePasswortLoginForm
      v-else-if="view === LoginView.Password"
      @switch-to-mail="view = LoginView.Email"
      @two-factor-challenge="setrequestAccessToken"
    />

    <TwoFactorChallengeForm
      v-else-if="view === LoginView.TwoFactor"
      @switch-to-password="view = LoginView.Password"
      @logging-in="loggingIn"
    />
  </AuthCardShell>
</template>
