<template>
    <footer
      class="hidden md:block text-gray-700 text-sm border-blue-100 border-t-2 bg-white"
      :class="maklertelefon || makleremail ? 'pt-6 pb-8' : 'py-3'"
    >
      <div class="container grid grid-cols-2 lg:grid-cols-3 md:gap-5">
        <div class="order-1 lg:order-0">
          <template v-if="maklertelefon || makleremail">
            <span class="whitespace-nowrap pr-1">Sie haben Fragen?</span>
            <span class="whitespace-nowrap">
            Kontaktieren Sie {{ maklerIsAgency ? 'uns' : 'mich' }} gerne!
          </span>
          </template>
        </div>

        <div class="order-0 col-span-2 lg:col-span-1 lg:row-span-2 lg:order-1 align-middle justify-self-center">
          <div
            v-if="firmenname"
            :class="{ 'hidden': logoLoadFailed }"
            class="w-50 h-16"
          >
            <img
              class="max-h-full max-w-full"
              :src="logoUrl"
              :alt="`Logo ${firmenname}`"
              @load="logoLoadFailed = false"
            >
          </div>
        </div>

        <div class="order-2 lg:order-2 lg:row-span-2 justify-self-end">
          <div class="flex flex-row gap-5 md:flex-col lg:gap-2 xl:flex-row xl:gap-5 print:hidden">
            <a
              v-if="isProductionEnv"
              href="#"
              onclick="CCM.openWidget();return false;"
            >Cookies</a>
            <RouterLink
              :to="{ name: Finanzmanager.DATENSCHUTZ }"
            >
              Datenschutz
            </RouterLink>
            <RouterLink
              :to="{ name: Finanzmanager.IMPRESSUM }"
            >
              Impressum
            </RouterLink>
            <a
              href="https://www.demv.de"
              target="_blank"
            >DEMV</a>
          </div>
        </div>

        <div
          v-if="firmenname && maklername"
          class="order-3 lg:order-3 col-span-full lg:col-span-1 space-y-2"
        >
          <div>
            <div>
              {{ firmenname }}
            </div>
            <div
              v-if="!maklerIsAgency && firmenname !== maklername"
              class="font-semibold"
            >
              {{ maklername }}
            </div>
          </div>

          <div class="flex flex-row flex-wrap gap-0 flex-fill ">
            <div
              v-if="maklertelefon"
              class="flex flex-row items-center space-x-2 mr-5"
            >
              <DsIcon
                name="phone"
                variant="regular"
                class="text-gray-500"
              />
              <a
                :href="`tel:${maklertelefon}`"
              >
                {{ maklertelefon }}
              </a>
            </div>
            <div
              v-if="makleremail"
              class="flex flex-row items-center space-x-2 flex-shrink-1 min-w-0 max-w-full"
            >
              <DsIcon
                name="envelope"
                variant="regular"
                class="text-gray-500"
              />
              <a
                :href="`mailto:${makleremail}`"
                class="truncate"
              >
                {{ makleremail }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
</template>

<script lang="ts" setup>
import { DsIcon } from '@demvsystems/design-components';
import { computed, ref } from 'vue';

import { Finanzmanager } from '@/application/router/types/types';
import { storeToRefs } from "pinia";
import { useBaseDataStore } from "@/user/store/baseDataStore";
import { isProductionEnv } from "@/application/utils/envInfo";

const {
  firmenname,
  makleremail,
  maklertelefon,
  maklername,
  maklerIsAgency,
  maklerOrAgencyUid,
} = storeToRefs(useBaseDataStore());

const logoUrl = computed(() => {
  const maklerOrAgency = maklerIsAgency.value ? 'f' : 'm'
  const byUidPostFix = maklerOrAgencyUid.value !== null ? `/${maklerOrAgency}/${maklerOrAgencyUid.value}` : ''
  return `/api/makler/logo${byUidPostFix}`
})

const logoLoadFailed = ref(true);
</script>

<style scoped>
a {
  @apply text-gray-700 hover:text-blue-700
}
</style>
