<template>
  <div>
    <div v-show="!submitted">
      <DsForm
        :validation-errors="errors"
        @submit.prevent
      >
        <DsAlert type="error" v-if="errorMsg" class="mb-5">{{ errorMsg }}</DsAlert>

        <DsFormGroup
          help="Nach Ihrer Anmeldung erhalten Sie einen Link, den Sie bestätigen müssen.
          Es ist kein Passwort notwendig."
          validation-name="email"
          label="E-Mail Adresse"
        >
          <DsInput
            v-model="form.email"
            immediate
            type="email"
            size="lg"
            required
          />
        </DsFormGroup>

        <div class="mt-10 space-y-2">
          <DsButton
            type="submit"
            variant="primary"
            size="lg"
            class="w-full"
            :handler="loginHandler"
          >
            Anmelden
          </DsButton>
          <div class="max-lg:hidden text-muted text-center">Oder</div>
          <DsButton
            variant="outline"
            size="lg"
            class="w-full"
            @click="emit('switch-to-password')"
          >
            Über Passwort anmelden
          </DsButton>
        </div>
      </DsForm>
    </div>

    <div
      v-show="submitted && !loading"
      class="space-y-5"
    >
      <DsAlert type="success">
        Sofern Sie einen Login besitzen, haben wir Ihnen eine Mail
        mit einem Zugangslink an <b>{{ form.email }}</b> verschickt.
        Bitte überprüfen Sie Ihren Posteingang (sowie den Spamordner).
      </DsAlert>

      <DsButton
        variant="outline"
        size="lg"
        class="w-full"
        :handler="onReset"
      >
        Mit anderen Daten anmelden
      </DsButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DsAlert, DsButton, DsForm, DsFormGroup, DsInput } from '@demvsystems/design-components';

import { mailLogin } from '@/api/auth/authApi';
import { trackEvent } from '@/application/utils/matomo';
import { TrackEventCategory } from '@/application/utils/matomo/events';

import { useForm } from "@/api/lib/integration";
import { ref } from "vue";

const emit = defineEmits(['switch-to-password']);

const submitted = ref(false);

const { loading, form, errors, submit, reset, errorMsg } = useForm(
  mailLogin,
  { email: '' },
  () => { submitted.value = true },
);

const onReset = () => {
  reset();
  submitted.value = false;
};

const loginHandler = async () => {
  await submit();
  trackEvent({
    category: TrackEventCategory.LOGIN_VIA_MAIL,
    action: 'Anmelden',
    name: 'submit',
  });
};
</script>
