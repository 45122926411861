<script setup lang="ts">
import PageHeaderUnauthenticated from "@/application/components/PageHeaderUnauthenticated.vue";
import { useUserStore } from "@/user/store/userStore";
import PageHeaderAuthenticated from "@/application/components/PageHeaderAuthenticated.vue";

interface Props {
  useAuthenticatedHeader?: boolean
}

withDefaults(defineProps<Props>(), {
  useAuthenticatedHeader: true
})
const userStore = useUserStore()
</script>

<template>
  <header class="sticky-header bg-white shadow">
    <nav class="md:container mx-4 py-2">
      <div class="flex flex-row items-center justify-between gap-2 h-11">
        <PageHeaderAuthenticated v-if="userStore.isAuthenticated && useAuthenticatedHeader" />
        <PageHeaderUnauthenticated v-else />
      </div>
    </nav>
  </header>
</template>
