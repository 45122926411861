<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <section class="flex flex-col gap-5 border rounded mb-7 w-full p-4 bg-white">
    <section class="mb-14">
      <h5 class="mb-2">
        Mein Partner ist
      </h5>

      <DsFieldError
        v-for="error in formErrors.anrede"
        :key="error"
      >
        {{ error }}
      </DsFieldError>

      <OptionGroup
        id="formError.partner.anrede"
        has-icons
        :options="[
          {value: 2, label: 'weiblich', icon: 'icomoon/female',
           iconClass: 'gender'},
          {value: 1, label: 'männlich', icon: 'icomoon/male',
           iconClass: 'gender'},
        ]"
        :value="form.anrede"
        option-width-classes="w-1/3"
        @change="set('anrede', $event)"
      />

      <div class="flex flex-col gap-5 w-full lg:w-2/3 mx-auto">
        <DsFormGroup
          label="Vorname"
          id="formError.partner.vorname"
        >
          <DsInput
            :model-value="form.vorname"
            @update:model-value="set('vorname', $event)"
            size="lg"
          />
          <DsFieldError
            v-for="error in formErrors.vorname"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
        <DsFormGroup
          label="Nachname"
          id="formError.partner.nachname"
        >
          <DsInput
            :model-value="form.nachname"
            @update:model-value="set('nachname', $event)"
            size="lg"
          />
          <DsFieldError
            v-for="error in formErrors.nachname"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
        <DsFormGroup
          label="Geburtsdatum"
          id="formError.partner.geburtsdatum"
        >
          <DateInput
            :model-value="form.geburtsdatum"
            @update:model-value="set('geburtsdatum', $event)"
            size="lg"
          />
          <DsFieldError
            v-for="error in formErrors.geburtsdatum"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
      </div>
    </section>

    <section class="flex flex-col gap-5 w-full lg:w-2/3 mx-auto">
      <h5>
        Berufsstatus und Einkommen meines Partners
      </h5>
      <DsFormGroup label="Berufsstatus">
        <DsSelect
          :model-value="form.taetigkeitsstatus"
          @update:model-value="set('taetigkeitsstatus', $event)"
          :data="taetigkeitsstatus"
          placeholder=""
          size="lg"
        />
        <DsFieldError
          v-for="error in formErrors.taetigkeitsstatus"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>
      <DsFormGroup label="Bruttoeinkommen">
        <MoneyInput
          :model-value="form.brutto"
          size="lg"
          as-string
          @update:model-value="set('brutto', handleMoneyValue($event))"
        />
        <DsFieldError
          v-for="error in formErrors.brutto"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>

      <DsRadioGroup
        :model-value="form.isBruttoJahresGehalt === null ? '' : form.isBruttoJahresGehalt ? '1' : '0'"
        :variant="isMobile ? 'button-col' : 'button'"
        class="w-full bg-white"
        @update:model-value="form.isBruttoJahresGehalt = $event === '1'"
      >
        <DsRadioButton
          value="1"
          style="height: 48px"
        >
          im Jahr
        </DsRadioButton>

        <DsRadioButton
          value="0"
          style="height: 48px"
        >
          im Monat
        </DsRadioButton>
      </DsRadioGroup>
      <DsFormGroup label="Nettoeinkommen">
        <MoneyInput
          :model-value="form.netto"
          size="lg"
          as-string
          @update:model-value="set('netto', handleMoneyValue($event))"
        />
        <DsFieldError
          v-for="error in formErrors.netto"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>

      <DsRadioGroup
        :model-value="form.isNettoJahresGehalt === null ? '' : form.isNettoJahresGehalt ? '1' : '0'"
        :variant="isMobile ? 'button-col' : 'button'"
        class="w-full bg-white"
        @update:model-value="form.isNettoJahresGehalt = $event === '1'"
      >
        <DsRadioButton
          value="1"
          style="height: 48px"
        >
          im Jahr
        </DsRadioButton>

        <DsRadioButton
          value="0"
          style="height: 48px"
        >
          im Monat
        </DsRadioButton>
      </DsRadioGroup>
    </section>

    <section class="flex flex-col mt-5 w-full lg:w-2/3 mx-auto">
      <h5 class="mb-7">
        Der Krankenversicherungsstatus meines Partners lautet
      </h5>
      <DsFieldError
        v-for="error in formErrors.krankenversicherungsstatus"
        :key="error"
      >
        {{ error }}
      </DsFieldError>

      <DsRadioGroup
        id="formError.partner.krankenversicherungsstatus"
        :model-value="form.krankenversicherungsstatus === null ? '' : `${form.krankenversicherungsstatus}`"
        variant="button-col"
        class="w-full bg-white"
        @update:model-value="form.krankenversicherungsstatus = $event === '' ? null : Number($event)"
      >
        <DsRadioButton
          v-for="{label, value} in krankenversicherungsstatus"
          :key="value"
          :value="`${value}`"
          style="height: 48px"
        >
          {{ label }}
        </DsRadioButton>
      </DsRadioGroup>
    </section>

    <section class="flex flex-col mt-5 w-full lg:w-2/3 mx-auto mb-7">
      <h5 class="mb-7">
        Mein Partner leidet an einer schweren Krankheit
        <DsButton
          class="ml-2"
          variant="outline"
          icon="info"
          size="sm"
          title="Weitere Infos anzeigen"
          @click="openModal"
        />
      </h5>
      <DsFieldError
        v-for="error in formErrors.hatSchwereKrankheit"
        :key="error"
      >
        {{ error }}
      </DsFieldError>

      <OptionGroup
        id="formError.partner.hatSchwereKrankheit"
        has-icons
        :options="[
          {label: 'Ja', labelClasses: `${form.hatSchwereKrankheit === 1 ? 'text-green-700' : 'flex flex-col'}`, value: 1, icon: 'icomoon/check', iconClass: `check ${form.hatSchwereKrankheit === 1 ? 'fill-green-700' : ''}`},
          {label: 'Nein', labelClasses: `${form.hatSchwereKrankheit === 0 ? 'text-red-700' : 'flex flex-col'}`, value: 0, icon: 'icomoon/close', iconClass: `close ${form.hatSchwereKrankheit === 0 ? 'fill-red-700' : ''}`}
        ]"
        :value="form.hatSchwereKrankheit"
        option-width-classes="w-1/3"
        @change="set('hatSchwereKrankheit', $event)"
      />

      <div v-if="form.hatSchwereKrankheit">
        <DsFormGroup label="Diagnose">
          <DsTextarea
            :model-value="form.diagnose === null ? undefined : form.diagnose"
            placeholder="Bitte geben Sie hier Ihre Diagnose ein."
            size="lg"
            :rows="8"
            @update:model-value="form.diagnose = $event === undefined ? null : $event"
          />
          <DsFieldError
            v-for="error in formErrors.diagnose"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
      </div>
    </section>

    <SchwereKrankheitenModal
      :show="showModal"
      @close-modal="closeModal"
      @open-modal="openModal"
    />
  </section>
</template>

<script setup lang="ts">
import { DsButton,
  DsFieldError,
  DsFormGroup,
  DsInput,
  DsRadioButton,
  DsRadioGroup,
  DsSelect,
  DsTextarea } from '@demvsystems/design-components';
import { sortBy } from 'lodash-es';
import { ref } from 'vue';

import DateInput from '@/application/components/form-elements/DateInput/DateInput.vue';
import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import { useDetectMobile } from '@/application/composables/detectMobile';
import OptionGroup from '@/checkup/components/OptionGroup.vue';
import SchwereKrankheitenModal from '@/checkup/components/steps/SchwereKrankheitenModal.vue';
import { toLabelAndValue } from '@/checkup/utils/demvWerte';
import { demvWerte } from '@/checkup/state';
import type { Partner } from '@/checkup/types/types';

interface Props {
  form: Partner;
  formErrors: Record<string, never>;
}

const props = defineProps<Props>();

const emit = defineEmits(['clear']);

const { isMobile } = useDetectMobile();

const showModal = ref(false);

const openModal = () => {
  showModal.value = true;
};
const closeModal = () => {
  showModal.value = false;
};

const set = (field: keyof Partner, value: any) => {
  //@ts-ignore
  //eslint-disable-next-line vue/no-mutating-props
  props.form[field] = value;
  emit('clear', `${field}`);
};

// TODO: remove when backend accepts value as float
const handleMoneyValue = (value: number | string | null): number | string | null => {
  if (value === null || value === '0' || value === 0) return null;
  if (typeof value === 'number') return value.toString();
  return value;
};

const taetigkeitsstatus = sortBy(toLabelAndValue(demvWerte.taetigkeitstatus), 'label');

const krankenversicherungsstatus = toLabelAndValue(demvWerte.krankenversicherungsstatus);
</script>
