<script setup lang="ts">

import { inject } from 'vue';

const y = inject('y');
</script>

<template>
  <div class="pb-2 pt-4 container sticky top-0 z-[200]">
    <div
      class="flex gap-2 justify-between items-center p-2 rounded-lg -mx-2"
      :class="{'shadow bg-white transition-colors duration-75 ease-in-out': y > 0 }"
    >
      <slot />
    </div>
  </div>
</template>
