<template>
  <!-- Navigation-Dropdown for responsive View -->
  <div
    class="flex md:hidden w-full flex-row justify-center mt-4"
  >
    <DsDropdown
      v-if="visibleSteps.length > 1"
      :items="visibleSteps"
    >
      <template #button="{isOpen}">
        <div
          v-if="currentStepDetails"
          class="flex flex-row gap-2 justify-center items-center"
        >
          <SvgVue
            :key="currentStepDetails.icon"
            :icon="`icomoon/${currentStepDetails.icon}`"
            class="h-6 w-6 fill-gray-500"
          />
          <span
            class="truncate text-2xl"
            :title="currentStepDetails.name"
          >
            {{ currentStepDetails.name }}
          </span>
          <DsIcon
            name="chevron-down"
            size="sm"
            class="mr-2"
            :rotation="isOpen ? '180' : undefined"
          />
        </div>
      </template>
      <template #item="{ item }">
        <router-link
          :to="{name: item.name }"
          class="flex flex-row gap-2 justify-start items-center p-3 text-gray-800
          hover:text-black hover:no-underline"
          active-class="bg-gray-100"
        >
          <SvgVue
            :icon="`icomoon/${item.icon}`"
            class="h-4 w-4 fill-gray-500"
          />
          {{ item.name }}
        </router-link>
      </template>
    </DsDropdown>
    <div
      v-else
      class="flex flex-row gap-2 justify-end items-center"
    >
      <SvgVue
        :icon="`icomoon/female`"
        class="h-6 w-6 fill-gray-700"
      />
      <span
        class="truncate text-2xl"
        title="Grunddaten"
      >
        Grunddaten
      </span>
    </div>
  </div>

  <!-- Gleichmäßige Anordnung der Wizard-Icons -->
  <div
    ref="target"
    class="flex flex-row justify-center mt-2 md:mt-10"
  >
    <div class="w-full max-w-7xl px-20 hidden md:block">
      <div class="flex justify-center">
        <div
          v-for="({name, icon, isActive, isAvailable, isLast}) in steps"
          :key="name"
          :class="[isLast ? 'flex -mr-2.5' : 'flex flex-grow']"
        >
          <router-link
            :to="{ name }"
            class="Wizard-icon"
            :class="[isAvailable ? 'visible' : 'invisible']"
          >
            <span
              class="Wizard-infotext"
              :class="[isActive ? 'block' : 'hidden']"
              v-text="name"
            />
            <SvgVue
              :icon="`icomoon/${icon}`"
              class="h-[26px] w-[26px] -ml-2"
              :class="[
                isActive ? 'fill-blue-300' : 'fill-blue-500',
                isAvailable ? 'visible' : 'invisible'
              ]"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <!-- Progressbar and Encourage-Text -->
  <div class="flex flex-row justify-center sticky top-0 z-10 md:static">
    <div
      class="w-full max-w-7xl md:px-20 transition-[padding] duration-[50ms] ease-in-out"
      :class="[sticking ? 'px-0' : 'px-4']"
    >
      <div
        class="w-full h-2 rounded-full bg-gray-200 relative"
        :class="[sticking && 'rounded-none']"
      >
        <div
          class="w-[0.5%] h-2 rounded-full bg-blue-200 absolute transition-[width] duration-[300ms] ease-in-out"
          :class="[sticking && 'rounded-none']"
          :style="{width: `${progress}%` }"
        >
          <div class="Wizard-progress-position">
            <div
              class="hidden md:flex Wizard-encourageText rounded px-2.5 shadow"
            >
              <div class="text-sm">
                {{ encourage.text }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-[0.5%] h-2 rounded-full bg-blue-500 absolute z-10 transition-[width] duration-[300ms] ease-in-out"
          :style="{width: `${currentProgress}%` }"
          :class="[sticking && 'rounded-none']"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DsDropdown, DsIcon } from '@demvsystems/design-components';
import { computed, onMounted, onUnmounted, ref } from 'vue';

import SvgVue from '@/application/components/SvgVue';
import { currentRoute, currentStep } from '@/checkup/state';
import { getStepIndex, Step, StepOrder } from '@/checkup/utils/steps';

const target = ref();
const sticking = ref(false);

const observer = new IntersectionObserver(
  ([entry]) => {
    sticking.value = !entry.isIntersecting;
  },
  { threshold: 0, rootMargin: '-61px' },
);

onMounted(() => {
  observer.observe(target.value);
});

onUnmounted(() => {
  if (!target.value) {
    return;
  }

  observer.unobserve(target.value);
});

type TemplateSteps = {
  name: string,
  isActive: boolean,
  isAvailable: boolean,
  isLast: boolean,
  icon: string,
  index: number,
}[];

type EncourageStep = [string, number];

type StepIcons = { [key in Step]: string };

const Encourages = Object.freeze<Record<number, EncourageStep>>({
  0: ['Aller Anfang ist schwer', 1],
  10: ['Schon sind wir beim Beruf', 2],
  20: ['Jetzt noch die Finanzen', 2],
  30: ['Einfach weitermachen', 3],
  40: ['Gesundheit ist das höchste Gut', 4],
  50: ['Bergfest', 5],
  60: ['Fast geschafft', 6],
  70: ['Hoppla, nicht vergessen', 7],
  80: ['Endspurt', 8],
  90: ['Ziel', 9],
});

const stepIcons: StepIcons = {
  [Step.GRUNDDATEN]: 'female',
  [Step.BERUF]: 'job',
  [Step.FINANZEN]: 'work',
  [Step.WOHNEN]: 'house',
  [Step.GESUNDHEIT]: 'health',
  [Step.FAHRZEUGE]: 'fahrzeuge',
  [Step.FREIZEIT]: 'freizeit',
  [Step.PARTNER]: 'partner',
  [Step.KINDER]: 'children',
  [Step.ABSCHLUSS]: 'flag',
};

const maxStepIndex = Object.keys(Step).length - 1;

function getStepicon(step: Step) {
  return stepIcons[step];
}

function calcProgress(step: number, max: number, min = 0): number {
  return Math.max(min, Math.min((step / max) * 100, 100));
}

const currentStepDetails = computed((): null | { name: string, step: Step, icon: string } => {
  const name = currentRoute.value?.name;
  if (!name) return null;

  const step = Step[name.toString().toUpperCase() as keyof typeof Step];
  return {
    name: name.toString(),
    step,
    icon: getStepicon(step),
  };
});

const progress = computed((): number => calcProgress(currentStep.value, maxStepIndex));
const currentProgress = computed((): number => {
  const step = currentStepDetails.value?.step ?? null;
  if (step === null) {
    return progress.value;
  }
  const i = getStepIndex(step);
  return calcProgress(i, maxStepIndex, 1);
});

const encourage = computed((): { text: string, icon: string } => {
  const step = Math.min(Math.floor(progress.value / 10) * 10, 90);
  const [text, iconNr] = Encourages[step];
  return { text, icon: `emotion-${iconNr}` };
});

const steps = computed((): TemplateSteps => StepOrder.map((name, index) => {
  const step = Step[name.toUpperCase() as keyof typeof Step];
  const isActive = currentStepDetails.value?.step === step;
  const isAvailable = index <= currentStep.value || isActive;

  return {
    name: step,
    isActive,
    isAvailable,
    isLast: index === maxStepIndex,
    icon: getStepicon(step),
    index,
  };
}));

const visibleSteps = computed((): TemplateSteps => steps.value
  .filter(({ isAvailable }) => isAvailable));
</script>
