<template>
  <div :style="{ width: `${maxWidth}px` }">
    <img
      :class="{ 'hidden': failed }"
      :src="source"
      alt=""
      class="rounded self-center"
      @load="emit('load'); failed = false"
      @error="emit('error'); failed = true"
    >
    <slot v-if="failed">
      <div class="rounded-lg h-9 w-20 border border-dashed" />
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

interface Props {
  id: number,
  maxHeight?: number,
  maxWidth?: number,
}

const props = withDefaults(defineProps<Props>(), {
  maxHeight: 48,
  maxWidth: 90,
});

const emit = defineEmits(['load', 'error']);

const failed = ref(true);
// eslint-disable-next-line max-len
const source = computed(() => `/api/gesellschaft/${props.id}/logo?maxHeight=${props.maxHeight}&maxWidth=${props.maxWidth}`);
</script>
