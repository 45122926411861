<script setup lang="ts">
import { DsButton } from "@demvsystems/design-components";
import { ref } from "vue";
import { onClickOutside } from '@vueuse/core'

interface Props {
  icon?: string,
  label?: string,
  right?: string,
}

withDefaults(defineProps<Props>(), {
  right: 'right-0'
})

const emit = defineEmits(['show'])

const show = ref(false)
const target = ref(null)

onClickOutside(target, () => show.value = false)

function handleClick() {
  show.value = !show.value;
  emit('show')
}
</script>

<template>
  <div class="relative">
  <div ref="target">
    <DsButton @click="handleClick" class="ml-1" v-if="!label" variant="clear" :icon="icon"></DsButton>
    <DsButton @click="handleClick" class="ml-1" v-if="label" variant="clear" :icon="icon">{{ label }}</DsButton>
    <div
      class="shadow-lg mt-4 p-4 space-y-3 rounded bg-white absolute"
      :class="[right, {'hidden': !show}]"
    >
      <slot />
    </div>
  </div>
</div>
</template>
