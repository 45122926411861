import AppCore from "@/application/AppCore.vue";
import { createRouter, createWebHistory } from "vue-router";
import { createPinia } from "pinia";
import { routes } from "@/application/router/routes"
import { authenticateGuard } from "@/application/router/guards/authenticateGuard";
import '../css/app.scss';
import passwordHealth from "@/application/plugins/passwordHealth";
import '@/application/resources/Icons';
import { SvgVue } from "@/application/plugins/svgVue";
import { vaporAssetPlugin } from "@/application/plugins/vapor";
import { setRouter } from "@/api/lib/routerRef";
import { checkupBeforeGuard } from "@/application/router/guards/checkupBeforeGuard";
import { checkupAfterGuard } from "@/application/router/guards/checkupAfterGuard";
import { cleanUpServiceWorker } from "@/application/utils/worker/cleanUpServiceWorker";
import { registerServiceWorker } from "@/application/utils/worker/registerServiceWorker";
import { createApp } from 'vue';
import { initSentry } from "@/application/plugins/sentry";
import VueMatomo from 'vue-matomo';
import type { URLOpenListenerEvent } from '@capacitor/app';
import { App } from '@capacitor/app';
import { routeTrackingGuard } from '@/application/router/guards/routeTrackingGuard';
import { isProductionEnv, isStagingEnv, onNative } from "@/application/utils/envInfo";
import { liveUpdateReload, liveUpdateSync } from "@/application/utils/liveUpdate";
import * as LiveUpdates from '@capacitor/live-updates';

const pinia = createPinia();
const router = createRouter({
  scrollBehavior: () => ({ top: 0 }),
  history: createWebHistory(),
  routes
});
router.beforeEach(authenticateGuard);
router.beforeEach(checkupBeforeGuard);
router.afterEach(checkupAfterGuard);
router.beforeEach(routeTrackingGuard)
setRouter(router);

async function initApp() {
  await LiveUpdates.setConfig({
    channel: isStagingEnv ? 'Staging' : 'Production',
  });

  App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
    const parsedUrl = new URL(event.url);
    const query = Object.fromEntries(parsedUrl.searchParams.entries());

    if (parsedUrl.pathname) router
      .push({ path: parsedUrl.pathname, query })
      .then((e) => {
        e instanceof Error && router.push({ path: parsedUrl.pathname, query })
      });
  });

  App.addListener('resume', async () => {
    await onNative(liveUpdateReload);
  });

  await onNative(liveUpdateSync);

  const app = createApp(AppCore);
  initSentry(app, router);

  if (isProductionEnv) {
    app.use(VueMatomo, {
      host: 'https://analytics.demv-systems.de',
      siteId: '6',
    });
  }

  app.use(SvgVue)
  app.use(passwordHealth);
  app.use(router);
  app.use(pinia);
  app.use(vaporAssetPlugin);
  app.mount('#app');

  await cleanUpServiceWorker();


  if (isStagingEnv || isProductionEnv) {
    registerServiceWorker();
  }

  if (isProductionEnv) {
    window._paq.push(['trackPageView']);
    window._paq.push(['enableLinkTracking']);
  }
}

initApp().then();
