import { useGet, usePost, useDelete } from "../lib/integration";

import type {
  ConfirmPasswordRequestData,
  LoginRequestData,
} from "./types";
import { z } from "zod";
import { appUrl } from "@/application/utils/envInfo";
import type { requestAccessTokenChallenge, requestAccessTokenResponse } from "./types";

export const recoveryCodesPdfUrl = `${appUrl}/api/user/recovery-codes`;

export const passwordLogin = usePost<LoginRequestData, string>('/api/login/password');
export const mailLogin = usePost<{email: string}, void>('/api/login/mail');
export const requestAccessTokenLogin = usePost<requestAccessTokenChallenge, requestAccessTokenResponse>('/api/login/token');

export const logout = usePost('/api/logout', { preventRedirect: true });

export const isAuthenticated = useGet<void, boolean>('/api/user/authenticated', { schema: z.boolean() });

export const confirmPassword = usePost<ConfirmPasswordRequestData, void>('/api/user/confirm-password');
export const confirmTwoFactorAuthentication = usePost<{ code: string }, void>('/api/user/confirmed-two-factor-authentication');
export const enableTwoFactor = usePost('/api/user/two-factor-authentication');
export const getTwoFactorQrCode = useGet<void, { svg: string, url: string }>('/api/user/two-factor-qr-code');
export const getTwoFactorRecoveryCodes = useGet<void, string[]>('/api/user/two-factor-recovery-codes');
export const disableTwoFactor = useDelete<void, void>('/api/user/two-factor-authentication');
export const getConfirmedPasswordStatus = useGet<void, { confirmed: boolean }>('/api/user/confirmed-password-status');
