<template>
  <div
    v-if="show"
    class="flex flex-row justify-center w-full bg-transparent px-4 py-3"
  >
    <div class="flex flex-row items-center mb-0 px-5 py-1 bg-amber-100 text-orange-800 border-orange-500 bg-orange-100">
      <span class="text-sm md:text-base text-center py-1">
        Sie haben noch kein Passwort gesetzt.
        <RouterLink
          :to="{ name: Finanzmanager.ACCOUNT }"
        >
          <span class="text-orange-800 font-bold">Jetzt Passwort festlegen</span>
        </RouterLink>
      </span>
      <button @click="close" type="button" class="cursor-pointer py-2 pl-4" title="Nachricht ausblenden">
        <svg class="svg-inline--fa fa-xmark" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path class="" fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { useBaseDataStore } from '@/user/store/baseDataStore';
import { getLocalStorage, setLocalStorage } from '@/application/utils/localStorage';
import { Finanzmanager } from "@/application/router/types/types";
import { useRoute } from "vue-router";

const TTL = 60 * 60 * 24; // 1 day
const LS_KEY = 'password-banner';

const { hasPasswortSet } = useBaseDataStore();
const route = useRoute();

const show = ref(!hasPasswortSet && getLocalStorage(LS_KEY) === null && route.meta.public !== true);

const close = () => {
  show.value = false;
  setLocalStorage(LS_KEY, false, TTL);
};

</script>
