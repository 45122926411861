<template>
  <div class="flex flex-col rounded-lg overflow-hidden max-w-[480px]">
    <img
      class="card-img-top"
      :src="images[title]"
      :alt="title"
    >
    <div class="bg-gray-800 text-light py-1.5 px-3">
        <span class="font-semibold">
          {{ title }}
        </span>
    </div>
    <div class="flex flex-col bg-white border rounded-b-lg divide-y overflow-hidden">
      <RouterLink
        v-for="thema in themen"
        :key="thema.slug"
        :to="`/mein-bedarf/${route.params.bedarfHash}/${thema.slug}`"
        class="text-sm text-gray-800 py-2 px-3 hover:bg-blue-100"
      >
        <span v-html="thema.name" />
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import AltersvorsorgeImage from '../../../../../assets/bedarfsinfo/navigation/altersvorsorge.jpg';
import EinkommenssicherungImage from '../../../../../assets/bedarfsinfo/navigation/einkommen.jpg';
import SachversicherungenImage from '../../../../../assets/bedarfsinfo/navigation/sachversicherung.jpg';
import GesundheitsvorsorgeImage from '../../../../../assets/bedarfsinfo/navigation/gesundheit.jpg';
import GewerbeversicherungImage from '../../../../../assets/bedarfsinfo/navigation/gewerbe.jpg';
import { useRoute } from "vue-router";

const images = {
  'Altersvorsorge': AltersvorsorgeImage,
  'Einkommenssicherung': EinkommenssicherungImage,
  'Sachversicherungen': SachversicherungenImage,
  'Gesundheitsvorsorge': GesundheitsvorsorgeImage,
  'Gewerbeversicherung': GewerbeversicherungImage,
}

interface Props {
  title: string,
  themen: Array<{ slug: string, name: string }>,
}

defineProps<Props>();

const route = useRoute();
</script>
