<template>
  <Layout v-if="commonProps.age <= 70">
    <template #bars-mobile>
      <BarsMobile
        :income="income"
        :inflation="altersvorsorge.inflation"
        :monetary-need="monetaryNeed"
        :labels="labels"
      >
        <template #incomeBar>
          <MoneyInput
            v-model="income"
            placeholder="z.B. 2.000 €"
            class="text-gray-800 w-36"
          />
        </template>
        <template #segmentsBar>
          <Segment
            :height="altersvorsorge.gesetzlicheRente / monetaryNeed"
            horizontal
            class="text-sm"
          >
            Gesetzl. Rente<br>
            <div class="flex">
              <div class="flex-grow flex">
                <div
                  v-if="renteLoading"
                  class="spinner"
                />
                <MoneyInput
                  v-else
                  v-model="altersvorsorge.gesetzlicheRente"
                  placeholder="z.B. 2.000 €"
                  class="text-gray-800 w-36"
                />
              </div>
            </div>
          </Segment>
          <Segment
            v-if="altersvorsorge.bestehendeVorsorge > 0"
            :height="altersvorsorge.bestehendeVorsorge / monetaryNeed"
            light
            horizontal
            class="text-sm"
          >
            Private Vorsorge
            <MoneyInput
              v-model="altersvorsorge.bestehendeVorsorge"
              placeholder="z.B. 2.000 €"
              class="text-gray-800"
            />
          </Segment>
          <Segment
            v-if="gap > 0"
            :height="gap / monetaryNeed"
            dashed-outline
            horizontal
            class="text-secondary text-sm"
          >
            Lücke
            <strong v-text="format(gap)"/>
          </Segment>
        </template>
      </BarsMobile>
    </template>
    <template #bars-desktop>
      <BarsDesktop
        :income="income"
        :inflation="altersvorsorge.inflation"
        :monetary-need="monetaryNeed"
        :labels="labels"
        :gap="gap"
      >
        <template #incomeBar>
          <MoneyInput
            v-model="income"
            placeholder="z.B. 2.000 €"
            class="text-gray-800"
          />
        </template>
        <template #segmentsBar>
          <Segment
            v-if="gap > 0"
            :height="gap / monetaryNeed"
            dashed-outline
          >
            Versorgungs&shy;lücke<br>
            {{ format(gap) }}
          </Segment>
          <Segment
            v-if="altersvorsorge.bestehendeVorsorge > 0"
            :height="altersvorsorge.bestehendeVorsorge / monetaryNeed"
            light
          >
            Private Vorsorge
            <MoneyInput
              v-model="altersvorsorge.bestehendeVorsorge"
              placeholder="z.B. 2.000 €"
              class="text-gray-800"
            />
          </Segment>
          <Segment :height="altersvorsorge.gesetzlicheRente / monetaryNeed">
            Gesetzl. Rente<br>
            <div class="flex">
              <div class="flex-grow flex">
                <div
                  v-if="renteLoading"
                  class="spinner"
                />
                <MoneyInput
                  v-else
                  v-model="altersvorsorge.gesetzlicheRente"
                  placeholder="z.B. 2.000 €"
                  class="text-gray-800"
                />
              </div>
            </div>
          </Segment>
        </template>
      </BarsDesktop>
    </template>

    <template #controls>
      <div class="col-12">
        <ControlRow
          class="mb-7"
          title="Rentenbeginn"
        >
          <Slider
            v-if="commonProps.age <= 69"
            v-model="altersvorsorge.rentenbeginn"
            :min="commonProps.age"
            :max="70"
            :format="{suffix: 'J.'}"
            :min-label="`${commonProps.age}J.`"
            max-label="70J."
          />
          <div
            v-else
            class="flex flex-row gap-1"
          >
            <span>70 Jahre</span>
            <PopoverIcon>
              <div class="mr-2">
                Der maximale Rentenbeginn für den Altersvorsorgerechner liegt bei 70 Jahren.
              </div>
            </PopoverIcon>
          </div>
        </ControlRow>
      </div>
      <div class="col-12">
        <ControlRow
          class="mb-7"
          title="Rentenbedarf"
        >
          <Slider
            v-model="altersvorsorge.rentenbedarf"
            :max="100"
            :format="{suffix: '%'}"
            min-label="0%"
            max-label="100%"
          />
        </ControlRow>
      </div>
      <div class="col-12">
        <ControlRow
          class="mb-7"
          title="Inflation*"
        >
          <Slider
            v-model="altersvorsorge.inflation"
            :max="5"
            :step="0.1"
            :format="{suffix: '%', decimals: 1}"
            min-label="0%"
            max-label="5%"
          />
        </ControlRow>
      </div>

      <GrvCheckbox
        v-if="commonProps.calculateGrv"
        v-model="altersvorsorge.freiwilligeEinzahlung"
      />
      <div class="col-12">
        <ControlRow>
          <template #title>
            Private Vorsorge<br><small>(Bereits vorhanden)</small>
          </template>
          <MoneyInput
            v-model="altersvorsorge.bestehendeVorsorge"
            placeholder="z.B. 2.000 €"
            class="mb-1.5"
          />
          <div class="text-center text-sm">
            Monatlicher Rentenanspruch
          </div>
        </ControlRow>
      </div>
    </template>
    <template #rates>
      <div class="col-12 col-lg-4 offset-lg-8">
        <p class="text-sm mt-5 text-muted text-right mb-0">
          * Durchschnittliche Inflation der letzten 60. Jahren: <b>2,7%</b>
        </p>
      </div>
      <Rates
        v-model:ansparzinssatz="altersvorsorge.ansparzinssatz"
        v-model:entnahmezinssatz="altersvorsorge.entnahmezinssatz"
        :age="commonProps.age"
        :eintrittsalter="altersvorsorge.rentenbeginn"
        :gap="gap"
        gap-name="Rentenlücke"
      />
    </template>
  </Layout>
  <div
    v-else
    class="flex"
  >
    <DsAlert
      type="warning"
      label="Altersgrenze überschritten"
      class="mx-auto my-5"
    >
      Der Altersvorsorge-Rechner steht nur bis 70 Jahre zur Verfügung!
    </DsAlert>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRef } from 'vue';

import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import Slider from '@/application/components/Slider.vue';
import BarsDesktop from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/BarsDesktop.vue';
import BarsMobile from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/BarsMobile.vue';
import ControlRow from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/ControlRow.vue';
import GrvCheckbox from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/GrvCheckbox.vue';
import Layout from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/Layout.vue';
import Rates from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/Rates.vue';
import Segment from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/Segment.vue';
// eslint-disable-next-line max-len
import {
  useGesetzlicheRenteRechner
} from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/composables/calculators';
import { calculateMonetaryNeed } from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/utils';
import { useAltersvorsorgeStore } from '@/bedarf/components/altersvorsorge/altersvorsorge/store';
import { format } from '@/application/utils/money';

import { chartProps } from './chartProps';
import { DsAlert } from '@demvsystems/design-components';
import PopoverIcon from '@/application/components/PopoverIcon.vue';

export default defineComponent({
  components: {
    PopoverIcon,
    DsAlert,
    BarsDesktop,
    BarsMobile,
    Layout,
    Segment,
    ControlRow,
    GrvCheckbox,
    Slider,
    MoneyInput,
    Rates,
  },

  props: chartProps,

  setup(props) {
    const labels = {
      income: 'Nettoeinkommen',
      popoverText: 'Schreiben Sie Ihr Nettoeinkommen in das vorgegebene Feld',
      monetaryNeed: 'Rentenbeginn',
    };
    const income = ref(props.commonProps.income || 2000);
    const currentYear = new Date().getFullYear();

    const { altersvorsorge, patchDefaults } = useAltersvorsorgeStore();

    patchDefaults(props.data);

    const { loading: renteLoading } = useGesetzlicheRenteRechner(
      props.commonProps.age,
      income,
      toRef(altersvorsorge, 'inflation'),
      toRef(altersvorsorge, 'rentenbeginn'),
      toRef(altersvorsorge, 'gesetzlicheRente'),
    );

    const monetaryNeed = computed(() => {
      const duration = altersvorsorge.rentenbeginn - props.commonProps.age;
      return calculateMonetaryNeed(income.value, altersvorsorge.rentenbedarf, duration, altersvorsorge.inflation);
    });

    const gap = computed(() => Math.max(
      0,
      monetaryNeed.value
      - altersvorsorge.bestehendeVorsorge
      - altersvorsorge.gesetzlicheRente,
    ));

    return {
      // static
      labels,
      currentYear,

      // reactive
      altersvorsorge,
      renteLoading,
      income,

      // computed
      monetaryNeed,
      gap,

      // methods
      format,
    };
  },
});
</script>
