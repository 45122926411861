<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import type { Contract } from '@/api/contracts/types';
import ApiContainer from '@/application/components/ApiContainer.vue';
import { useContractStore } from '@/contracts/stores/contractStore';
import { useUnreadHintStore } from '@/application/store/unreadHintStore';
import { UnreadHintType } from '@/api/unreadHint/types';
import CardCollectionHeader from "@/application/components/CardCollectionHeader.vue";
import ContractsCollection from "@/contracts/components/ContractsCollection.vue";
import { TrackEventCategory } from '@/application/utils/matomo/events';
import { useTrackSearch } from "@/application/composables/useTrackSearch";
import { useBaseDataStore } from "@/user/store/baseDataStore";
import { ContractState } from "@/contracts/types/types";
import ButtonWithMenu from "@/application/components/ButtonWithMenu.vue";
import BooleanFilter from "@/application/components/filter/BooleanFilter.vue";
import { useFilters } from "@/application/filter/use/useFilters";
import { useSearchFilter } from "@/application/filter/use/useSearchFilter";
import { useActivatableFilter } from "@/application/filter/use/useActivatableFilter";
import ContractsMeta from '@/contracts/components/ContractsMeta.vue';

defineEmits(['openModal']);

const {
  contracts,
  contractsLoading,
  contractsError,
  depots,
  depotsLoading,
  depotsError,
  totalCostPerMonth,
} = storeToRefs(useContractStore());

const { filter: searchFilter, query } = useSearchFilter<Contract>([
  (contract) => contract.company.name,
  (contract) => contract.number,
  (contract) => contract.spartenName,
  (contract) => contract.meta?.kfz?.licensePlate,
  (contract) => contract.coinsured.map(i => i.firstName),
  (contract) => contract.coinsured.map(i => i.lastName),
  (contract) => contract.owner?.firstName,
  (contract) => contract.owner?.lastName,
  (contract) => contract.risikoort?.displayText,
  (contract) => contract.risikoort?.street,
  (contract) => contract.risikoort?.streetNumber,
  (contract) => contract.risikoort?.postcode,
  (contract) => contract.risikoort?.city,
  (contract) => contract.note
])
const statusIsAktivFilter = useActivatableFilter((contract: Contract) => contract.status.id === ContractState.AKTIV, true)
const statusIsStorniertFilter = useActivatableFilter((contract: Contract) => contract.status.id === ContractState.STORNIERT, false)
const statusIsSonstigeFilter = useActivatableFilter((contract: Contract) => contract.status.id === ContractState.SONSTIGE, false)
const statusIsRuhendFilter = useActivatableFilter((contract: Contract) => contract.status.id === ContractState.RUHEND, false)
const statusIsBeitragsfreiFilter = useActivatableFilter((contract: Contract) => contract.status.id === ContractState.BEITRAGSFREI, false)
const { filtered } = useFilters<Contract>(contracts, [
  statusIsAktivFilter,
  statusIsStorniertFilter,
  statusIsSonstigeFilter,
  statusIsRuhendFilter,
  statusIsBeitragsfreiFilter
], [
  { active: ref(true), filter: searchFilter }
])

const error = computed(() => contractsError.value || depotsError.value);
const { menuHints } = storeToRefs(useUnreadHintStore());
const unreadContractHints = computed(() => menuHints.value[UnreadHintType.Contract]);
const loading = computed(() => contractsLoading.value || depotsLoading.value);
const hasData = computed(() => contracts.value.length > 0 || depots.value.length > 0);
const { isCompany } = useBaseDataStore();
const { trackSearch } = useTrackSearch(`isCompany: ${isCompany}`, TrackEventCategory.CONTRACT_SEARCH)

const contractsCountByState = computed(() => {
  return {
    aktiv: contracts.value.filter(contract => contract.status.id === ContractState.AKTIV).length,
    storniert: contracts.value.filter(contract => contract.status.id === ContractState.STORNIERT).length,
    beitragsfreiOderRuhend: contracts.value.filter(contract => contract.status.id === ContractState.BEITRAGSFREI || contract.status.id === ContractState.RUHEND).length,
    sonstige: contracts.value.filter(contract => contract.status.id === ContractState.SONSTIGE).length,
  }
})

useContractStore().fetchContractsAndDepots();
</script>

<template>
  <div class="flex flex-col flex-grow mb-10">
    <CardCollectionHeader
      v-model="query"
      v-if="!loading && !error"
      :showContextInfo="contracts.length > 0"

      createNewButtonLabel="Vertrag anlegen"

      :searchEnabled="contracts.length > 0"
      searchPlaceholder="Verträge durchsuchen..."

      :unreadHintsCount="unreadContractHints"
      :unreadHintsType="UnreadHintType.Contract"
      unreadHintsLabel="Neue Verträge als gesehen markieren"

      @searchFocus="trackSearch('focus')"
      @openCreateModal="$emit('openModal')"
    >
      <template #left>
        <ContractsMeta
          :has-contracts="contracts.length > 0"
          :contracts-count="contractsCountByState.aktiv"
          :total-cost-per-month="totalCostPerMonth"
        />
      </template>
      <template #filter>
        <ButtonWithMenu icon="filter">
          <BooleanFilter v-model="statusIsAktivFilter.active.value" label="Aktiv" input-name="filter_contract_aktiv" />
          <BooleanFilter v-model="statusIsStorniertFilter.active.value" label="Storniert" input-name="filter_contract_storniert" />
          <BooleanFilter v-model="statusIsBeitragsfreiFilter.active.value" label="Beitragsfrei" input-name="filter_contract_beitragsfrei" />
          <BooleanFilter v-model="statusIsSonstigeFilter.active.value" label="Sonstige" input-name="filter_contract_sonstige" />
          <BooleanFilter v-model="statusIsRuhendFilter.active.value" label="Ruhend" input-name="filter_contract_ruhend" />
        </ButtonWithMenu>
      </template>
    </CardCollectionHeader>

    <div
      v-if="!loading && !error"
      class="lg:hidden container"
    >
      <ContractsMeta
        :has-contracts="contracts.length > 0"
        :contracts-count="contractsCountByState.aktiv"
        :total-cost-per-month="totalCostPerMonth"
      />
    </div>

    <ApiContainer
      :loading="loading"
      :error="error ?? undefined"
      :has-data="hasData"
      refresh-label="Verträge erneut laden"
      class="container"
      @refresh="() => useContractStore().fetchContractsAndDepots(true)"
    >
      <template #loading>
        Verträge werden geladen...
      </template>

      <template #default>
        <div class="col-12 flex flex-col items-center">
          <p class="pt-14 pb-5 text-center lead text-muted">
            Es liegen zur Zeit keine Verträge von Ihnen vor.
          </p>
        </div>
      </template>

      <template #success>
        <ContractsCollection :contracts="filtered" />
      </template>
    </ApiContainer>
  </div>
</template>
