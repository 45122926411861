<template>
  <Layout>
    <template #bars-mobile>
      <BarsMobile
        :income="income"
        :inflation="altersvorsorge.inflation"
        :monetary-need="monetaryNeed"
        :labels="labels"
      >
        <template #incomeBar>
          <MoneyInput
            v-model="income"
            placeholder="z.B. 2.000 €"
            class="text-gray-800 w-40"
          />
        </template>
        <template #segmentsBar>
          <Segment
            :height="altersvorsorge.pension / monetaryNeed"
            horizontal
            class="text-sm"
          >
            Pension<br/>
            <div class="flex">
              <div class="flex flex-grow">
                <MoneyInput
                  v-show="!pensionLoading"
                  v-model="altersvorsorge.pension"
                  placeholder="z.B. 2.000 €"
                  class="text-gray-800"
                />
              </div>
            </div>
          </Segment>
          <Segment
            v-if="altersvorsorge.bestehendeVorsorge > 0"
            :height="altersvorsorge.bestehendeVorsorge / monetaryNeed"
            light
            horizontal
            class="text-sm"
          >
            Private Vorsorge
            <MoneyInput
              v-model="altersvorsorge.bestehendeVorsorge"
              placeholder="z.B. 2.000 €"
              class="text-gray-800"
            />
          </Segment>
          <Segment
            v-if="altersvorsorge.freiwilligeEinzahlung"
            :height="altersvorsorge.gesetzlicheRente / monetaryNeed"
            horizontal
            class="text-sm"
          >
            Gesetzl. Rente
            <MoneyInput
              v-model="altersvorsorge.gesetzlicheRente"
              placeholder="z.B. 2.000 €"
              class="text-gray-800"
            />
          </Segment>
          <Segment
            v-if="gap > 0"
            :height="gap / monetaryNeed"
            dashed-outline
            horizontal
            class="text-secondary text-sm"
          >
            Lücke
            <strong v-text="format(gap)" />
          </Segment>
        </template>
      </BarsMobile>
    </template>

    <template #bars-desktop>
      <BarsDesktop
        :income="income"
        :inflation="altersvorsorge.inflation"
        :monetary-need="monetaryNeed"
        :labels="labels"
        :gap="gap"
      >
        <template #incomeBar>
          <MoneyInput
            v-model="income"
            placeholder="z.B. 2.000 €"
            class="text-gray-800"
          />
        </template>
        <template #segmentsBar>
          <Segment
            v-if="gap > 0"
            :height="gap / monetaryNeed"
            dashed-outline
          >
            Versorgungslücke<br>
            {{ format(gap) }}
          </Segment>
          <Segment
            v-if="altersvorsorge.freiwilligeEinzahlung"
            :height="altersvorsorge.gesetzlicheRente / monetaryNeed"
            :light="altersvorsorge.bestehendeVorsorge <= 0"
          >
            Gesetzl. Rente
            <MoneyInput
              v-model="altersvorsorge.gesetzlicheRente"
              placeholder="z.B. 2.000 €"
              class="text-gray-800"
            />
          </Segment>
          <Segment
            v-if="altersvorsorge.bestehendeVorsorge > 0"
            :height="altersvorsorge.bestehendeVorsorge / monetaryNeed"
            light
          >
            Private Vorsorge
            <MoneyInput
              v-model="altersvorsorge.bestehendeVorsorge"
              placeholder="z.B. 2.000 €"
              class="text-gray-800"
            />
          </Segment>
          <Segment :height="altersvorsorge.pension / monetaryNeed">
            Pension<br>
            <div class="flex">
              <div class="flex flex-grow">
                <div
                  v-if="pensionLoading"
                  class="spinner"
                />
                <MoneyInput
                  v-else
                  v-model="altersvorsorge.pension"
                  placeholder="z.B. 2.000 €"
                  class="text-gray-800"
                />
              </div>
            </div>
          </Segment>
        </template>
      </BarsDesktop>
    </template>

    <template #controls>
      <div class="col-12">
        <ControlRow class="mb-7">
          <template #title>
            Besoldung<br><small>(vor Pensionseintritt)</small>
          </template>
          <MoneyInput
            v-model="altersvorsorge.besoldungVorPension"
            placeholder="z.B. 2.000 €"
            class="mb-1.5"
          />
        </ControlRow>
      </div>
      <div class="col-12">
        <ControlRow
          class="mb-7"
          title="Dienstzeitbeginn"
        >
          <Slider
            v-model="altersvorsorge.dienstzeitbeginn"
            :min="currentYear - 50"
            :max="currentYear + 5"
          />
        </ControlRow>
      </div>
      <div class="col-12">
        <ControlRow
          class="mb-7"
          title="Pensionseintritt"
        >
          <Slider
            v-model="altersvorsorge.pensionseintritt"
            :min="currentYear"
            :max="currentYear + 50"
          />
        </ControlRow>
      </div>
      <div class="col-12">
        <ControlRow
          class="mb-7"
          title="Pensionsbedarf"
        >
          <Slider
            v-model="altersvorsorge.pensionsbedarf"
            :max="100"
            :format="{suffix: '%'}"
            min-label="0%"
            max-label="100%"
          />
        </ControlRow>
      </div>
      <div class="col-12">
        <ControlRow
          class="mb-7"
          title="Inflation*"
        >
          <Slider
            v-model="altersvorsorge.inflation"
            :max="5"
            :step="0.1"
            :format="{suffix: '%', decimals: 1}"
            min-label="0%"
            max-label="5%"
          />
        </ControlRow>
      </div>

      <GrvCheckbox
        v-model="altersvorsorge.freiwilligeEinzahlung"
        optional
      />
      <div class="col-12">
        <ControlRow>
          <template #title>
            Private Vorsorge<br><small>(Bereits vorhanden)</small>
          </template>
          <MoneyInput
            v-model="altersvorsorge.bestehendeVorsorge"
            placeholder="z.B. 2.000 €"
            class="mb-1.5"
          />
          <div class="text-center text-sm">
            Monatlicher Rentenanspruch
          </div>
        </ControlRow>
      </div>
    </template>
    <template #rates>
      <div class="col-12 col-lg-4 offset-lg-8">
        <p class="text-sm mt-5 text-muted text-right mb-0">
          * Durchschnittliche Inflation der letzten 60. Jahren: <b>2,7%</b>
        </p>
      </div>
      <Rates
        v-model:ansparzinssatz="altersvorsorge.ansparzinssatz"
        v-model:entnahmezinssatz="altersvorsorge.entnahmezinssatz"
        :age="commonProps.age"
        :eintrittsalter="eintrittsalter"
        :gap="gap"
        gap-name="Versorgungslücke"
      />
    </template>
  </Layout>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRef, watch } from 'vue';

import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import Slider from '@/application/components/Slider.vue';
import BarsDesktop from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/BarsDesktop.vue';
import BarsMobile from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/BarsMobile.vue';
import ControlRow from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/ControlRow.vue';
import GrvCheckbox from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/GrvCheckbox.vue';
import Layout from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/Layout.vue';
import Rates from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/Rates.vue';
import Segment from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/components/Segment.vue';
import { usePension } from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/composables/calculators';
import { calculateMonetaryNeed } from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/utils';
import { useAltersvorsorgeStore } from '@/bedarf/components/altersvorsorge/altersvorsorge/store';
import { format } from '@/application/utils/money';

import { chartProps } from './chartProps';
import type { Pension } from '@/api/rechner/types';

export default defineComponent({
  components: {
    BarsDesktop,
    BarsMobile,
    Layout,
    Segment,
    ControlRow,
    GrvCheckbox,
    Slider,
    MoneyInput,
    Rates,
  },

  props: chartProps,

  setup(props) {
    const labels = {
      income: 'Dienstbezüge (Netto)',
      popoverText: 'Schreiben Sie Ihre Dienstbezüge (Netto) in das vorgegebene Feld',
      monetaryNeed: 'Pensionsbeginn',
    };
    const income = ref(props.commonProps.income || 2000);
    const currentYear = new Date().getFullYear();

    const { altersvorsorge, patchDefaults } = useAltersvorsorgeStore();

    const isMindestruhegehalt = ref(false);

    const calculatedPension = computed<Pension>({
      get: () => ({
        pensionsbetrag: altersvorsorge.pension,
        pensionseintritt: altersvorsorge.pensionseintritt,
        isMindestruhegehalt: isMindestruhegehalt.value,
      }),
      set: (value: Pension) => {
        altersvorsorge.pension = value.pensionsbetrag;
        altersvorsorge.pensionseintritt = value.pensionseintritt;
        isMindestruhegehalt.value = value.isMindestruhegehalt;
      },
    });

    patchDefaults(props.data);

    const { loading: pensionLoading } = usePension(
      calculatedPension,
      toRef(altersvorsorge, 'dienstzeitbeginn'),
      toRef(altersvorsorge, 'besoldungVorPension'),
      toRef(altersvorsorge, 'pensionseintritt'),
    );

    const monetaryNeed = computed(() => {
      const duration = altersvorsorge.pensionseintritt - currentYear;
      return calculateMonetaryNeed(income.value, altersvorsorge.pensionsbedarf, duration, altersvorsorge.inflation);
    });

    const gap = computed(() => Math.max(
      0,
      monetaryNeed.value
        - altersvorsorge.bestehendeVorsorge
        - altersvorsorge.pension
        - altersvorsorge.gesetzlicheRente
    ));

    const eintrittsalter = computed(() => altersvorsorge.pensionseintritt - (currentYear - props.commonProps.age));

    watch(() => altersvorsorge.freiwilligeEinzahlung, (doesPay) => {
      if (doesPay) return;
      altersvorsorge.gesetzlicheRente = 0;
    });

    return {
      // static
      labels,
      currentYear,

      // reactive
      altersvorsorge,
      pensionLoading,
      income,
      eintrittsalter,

      // computed
      monetaryNeed,
      gap,

      // methods
      format,
    };
  },
});
</script>
