<template>
  <div class="bg-light flex-grow">
    <section class="md:container py-7 flex flex-row justify-center">
      <div class="flex flex-col w-full sm:w-10/12 lg:w-8/12 md:p-4 gap-2">
        <h1 class="hidden md:block text-center">
          {{ isCompany ? 'Unsere Daten' : 'Meine Daten' }}
        </h1>
        <span class="md:hidden mx-4 font-semibold text-lg">Kontaktinformationen</span>
        <DsCard>
          <Heading
            level="5"
            text="Kontaktinformationen"
            :icon="{name: 'user-circle', variant: 'regular', class: 'text-secondary mr-2'}"
            class="hidden md:block border-b border-gray-200 pb-2 mb-6 font-semibold"
          />
          <PersonalDataFormSkeleton v-if="loading" />
          <DsAlert
            v-else-if="error"
            class="my-10"
            type="error"
            label="Es ist leider ein Fehler aufgetreten."
          >
            {{ error }}
          </DsAlert>
          <PersonalDataForm
            v-else
            :pw-email="pwEmail"
            :phone="phone"
            :mobile="mobile"
            :mainAddress="mainAddress"
          />
        </DsCard>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { DsAlert, DsCard } from '@demvsystems/design-components';
import { storeToRefs } from "pinia";
import PersonalDataForm from "@/user/components/PersonalDataForm.vue";
import { useUserContactsAndAccountStore } from "@/user/store/userContactsAndAccountStore";
import { captureException } from "@sentry/vue";
import PersonalDataFormSkeleton from "@/user/components/PersonalDataFormSkeleton.vue";
import Heading from '@/application/components/Heading.vue';
import { useBaseDataStore } from '@/user/store/baseDataStore';

const loading = ref(false);
const error = ref<string | null>(null);

const { isCompany } = storeToRefs(useBaseDataStore());
const { mainAddress, phone, mobile, pwEmail } = storeToRefs(useUserContactsAndAccountStore());

(async () => {
  try {
    loading.value = true;
    await useUserContactsAndAccountStore().fetchUserContacts();
  } catch (err) {
    captureException(err);
    error.value = 'Es gab einen Fehler. Bitte laden Sie die Seite neu oder probieren Sie es zu einem späteren'
      + ' Zeitpunkt nochmal.'
  } finally {
    loading.value = false;
  }
})();
</script>
