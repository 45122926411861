<template>
  <section class="flex-grow py-8 bg-light">
    <div class="container">
      <h1 class="hidden md:block text-center">
        {{ archived ? 'Archivierte Hinweise' : 'Hinweise' }}
      </h1>
      <div class="flex flex-row justify-end">
        <DsButton
          variant="outline"
          icon="lightbulb"
          icon-variant="light"
          @click="show = true"
        >
          Einführung
        </DsButton>
        <OnboardingModal
          :show="show"
          @open="open"
          @close="close"
        />
      </div>
      <div class="row justify-center my-8">
        <div class="col text-center">
          <ul
            v-if="news.length > 0"
            class="border border-gray-100 rounded bg-white"
          >
            <li
              v-for="({ id, title, createdAt }, index) in news"
              :key="id"
            >
              <RouterLink
                v-slot="{ navigate }"
                :to="{ name: Finanzmanager.HINWEISE_SHOW, params: { id } }"
                custom
              >
                <div
                  class="text-sm md:text-base text-left flex flex-col md:flex-row md:items-center px-2 pt-3 pb-1 md:pb-3
                  cursor-pointer border-blue-100 hover:bg-blue-100 text-blue-700"
                  :class="index === news.length - 1 ? 'border-none' : 'border-b'"
                  @click="navigate"
                >
                  <div class="flex items-center">
                    <DsIcon
                      name="chevron-right"
                      size="sm"
                      class="mr-3"
                      fixed-width
                    />
                    {{ title }}
                    <DsBadge
                      v-if="unreadHints[UnreadHintType.News].has(id)"
                      type="primary"
                      class="ml-3"
                    >
                      Neu
                    </DsBadge>
                  </div>
                  <small
                    class="text-left text-muted flex-shrink-0 md:ml-auto mt-2 md:mt-0"
                    v-text="createdAt"
                  />
                </div>
              </RouterLink>
            </li>
          </ul>

          <div
            v-else
            class="text-center mt-2.5"
            v-text="'Keine Einträge'"
          />
        </div>
      </div>

      <div class="text-center">
        <RouterLink
          v-slot="{ navigate }"
          :to="{
            name: Finanzmanager.HINWEISE,
            query: { archived: archived ? 'false' : 'true'}
          } "
          custom
        >
          <DsButton
            variant="outline"
            :icon="archived ? 'mailbox' : 'box-archive'"
            icon-variant="light"
            @click="navigate"
          >
            {{ archived ? 'Hinweise' : 'Archiv' }}
          </DsButton>
        </RouterLink>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { DsBadge, DsButton, DsIcon } from '@demvsystems/design-components';
import { ref } from 'vue';
import { useUnreadHintStore } from '@/application/store/unreadHintStore';

import type { News } from '@/api/news/types';
import OnboardingModal from '@/application/components/OnboardingModal.vue';
import { Finanzmanager } from '@/application/router/types/types';
import { UnreadHintType } from "@/api/unreadHint/types";

interface Props {
  news: News[]
  archived?: boolean,
}

defineProps<Props>();

const show = ref(false);

const open = () => {
  show.value = true;
};
const close = () => {
  show.value = false;
};

const unreadHints = useUnreadHintStore();
</script>
