<script setup lang="ts">

</script>

<template>
  <div class="w-full text-center">
    <a href="/" class="text-dark font-bold">
      meine-finanzen.digital
    </a>
  </div>
</template>

<style scoped lang="scss">

</style>
