import type { Router } from "vue-router";

let routerRef: null | Router = null;

export function setRouter(router: Router) {
  routerRef = router;
}

export function getRouter() {
  return routerRef;
}
