import type { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { Finanzmanager } from "@/application/router/types/types";
import AccountView from "@/user/account/index.vue";
import DatenView from "@/user/data/index.vue";
import MainLayout from "@/application/layouts/MainLayout.vue";
import SimpleMessage from "@/application/components/SimpleMessage.vue";
import { confirmEmailChange, confirmEditEmail, confirmPasswordChange } from "@/api/account/accountApi";
import type { SimpleMessageProps } from "@/application/components/SimpleMessageProps";
import { useBaseDataStore } from '@/user/store/baseDataStore';
import type { ConfirmData } from "@/api/account/types";

export const accountRoutes: RouteRecordRaw[] = [
  {
    path: '/account',
    component: MainLayout,
    children: [
      {
        path: '',
        name: Finanzmanager.ACCOUNT,
        component: AccountView,
        beforeEnter: (to: RouteLocationNormalized) => {
          const { isCompany } = useBaseDataStore();
          to.meta.menuTitle = isCompany ? 'Unser Account' : 'Mein Account';
          to.meta.title = isCompany ? 'Unser Account' : 'Mein Account';
          return;
        },
      },
      {
        path: 'confirm-edit-email',
        component: SimpleMessage,
        meta: {
          public: true
        },
        props: (route): SimpleMessageProps<ConfirmData, string> => {
          return {
            confirmToken: route.query.confirmToken as string ?? null,
            apiCall: confirmEditEmail,
            afterConfirmationRoute: '/'
          }
        }
      },
      {
        path: 'confirm-email-change',
        component: SimpleMessage,
        meta: {
          public: true
        },
        props:
          (route): SimpleMessageProps<ConfirmData, string> => {
          return {
            confirmToken: route.query.confirmToken as string ?? null,
            apiCall: confirmEmailChange,
            afterConfirmationRoute: '/'
          }
        }
      },
      {
        path: 'confirm-password-change',
        component: SimpleMessage,
        meta: {
          public: true
        },
        props: (route): SimpleMessageProps<ConfirmData, string> => {
          return {
            confirmToken: route.query.confirmToken as string ?? null,
            apiCall: confirmPasswordChange,
            invalidatesAuth: true,
            afterConfirmationRoute: '/login'
          }
        }
      }
    ]
  },
  {
    path: '/daten',
    component: MainLayout,
    children: [
      {
        path: '',
        name: Finanzmanager.DATEN,
        component: DatenView,
        beforeEnter: (to: RouteLocationNormalized) => {
          const { isCompany } = useBaseDataStore();
          to.meta.menuTitle = isCompany ? 'Unsere Daten' : 'Meine Daten';
          to.meta.title = isCompany ? 'Unsere Daten' : 'Meine Daten';
          return;
        },
      },
    ]
  }
]
