import { useUserStore } from "@/user/store/userStore";
import type { RouteLocationNormalized } from "vue-router";
import { Finanzmanager } from "@/application/router/types/types";
import { shouldBlockReload } from "@/application/utils/localStorage";

export async function authenticateGuard(to: RouteLocationNormalized) {
  const { requestAccessToken = undefined, ...query } = { ...to.query };

  const route = Object.keys(query).length > 0
    ? `${to.path}?${new URLSearchParams(query)}`
    : `${to.path}`;

  const loginRedirect = {
    name: Finanzmanager.LOGIN,
    query: { redirect: encodeURIComponent(route), requestAccessToken },
  };

  if (requestAccessToken && to.name !== Finanzmanager.LOGIN) return loginRedirect;

  shouldBlockReload(true);
  if (useUserStore().isAuthenticated === null && await useUserStore().checkAuth()) await useUserStore().onLoggedIn();
  shouldBlockReload(false);

  if (useUserStore().isAuthenticated) return true;

  if (to.meta.public) return true;

  return loginRedirect;
}
