import type { Pinia, Store } from 'pinia';
import { defineStore, getActivePinia } from 'pinia';
import { useBaseDataStore } from "@/user/store/baseDataStore";
import { useUnreadHintStore } from "@/application/store/unreadHintStore";
import { isAuthenticated } from "@/api/auth/authApi";
import { call } from "@/api/lib/integration";
import { removeSecret, SecretNames } from "@/application/utils/secretStore";
import { resetErrors } from "@/checkup/state";
import { isProductionEnv } from '@/application/utils/envInfo';

type UserState = {
  isAuthenticated: boolean | null;
  requestAccessToken: string | null;
}

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}

export const useUserStore = defineStore('userStore', {

  state: (): UserState => ({
    isAuthenticated: null,
    requestAccessToken: null,
  }),

  actions: {
    async checkAuth() {
      await call(
        isAuthenticated(),
        (value) => { this.isAuthenticated = value },
        () => { this.isAuthenticated = false },
      );

      return this.isAuthenticated;
    },

    onLoggedOut () {
      resetErrors();
      removeSecret(SecretNames.AccessToken).then();
      removeSecret(SecretNames.ClientSecret).then();

      if (isProductionEnv) {
        window._paq.push(['resetUserId']);
      }

      this.isAuthenticated = false

      const activePinia = getActivePinia() as ExtendedPinia;
      activePinia?._s.forEach(store => store.$reset());
    },

    async onLoggedIn () {
      const activePinia = getActivePinia() as ExtendedPinia;
      activePinia?._s.forEach(store => store.$reset());

      this.isAuthenticated = true

      return Promise.all([
        useBaseDataStore().fetchBaseData(),
        useUnreadHintStore().fetchUnreadHints(),
      ]);
    }
  },
});
