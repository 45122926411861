<script setup lang="ts">
import { onBeforeUnmount, onMounted, provide, ref } from 'vue';
import { DsNotification } from "@demvsystems/design-components";
import { useScroll } from '@vueuse/core';

const isOnline = ref(true)
const onOnline = () => {
  isOnline.value = true
}

const onOffline = () => {
  isOnline.value = false
}

onMounted(() => {
  window.addEventListener('online', onOnline);
  window.addEventListener('offline', onOffline);
});

onBeforeUnmount(() => {
  window.removeEventListener('online', onOnline);
  window.addEventListener('offline', onOffline);
})

const main = ref<HTMLElement | null>(null);

const { y } = useScroll(main, { throttle: 50 });

provide('y', y);
</script>

<template>
  <header id="app-header" class="app-head"></header>
  <main ref="main" id="app-main">
    <div id="app-page-head"></div>
    <div id="app-page-content">
      <router-view />
    </div>
    <div id="app-page-footer"></div>
  </main>
  <footer id="app-footer"></footer>
  <div id="app-overlay"></div>
  <div class="relative z-[2006]">
    <DsNotification />
  </div>
</template>

<style lang="scss">
html, body {
  height: 100dvh;
  min-height: 100dvh;
  overflow: hidden;
  margin: 0;
}

#app {
  height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  min-height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  margin-top: env(safe-area-inset-top);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

#app-header {}

#app-main {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

#app-page-content {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

//#app-page-bottom-overlay {
//  position: absolute;
//  bottom: 0;
//  left: 0;
//  background: rgba(0, 200, 250, 0.4);
//  height: 100px;
//  min-height: 100px;
//  width: 100%;
//  pointer-events: none;
//}

#app-overlay {
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  z-index: 2005;
}
</style>
