export function getCookie(name: string) {
  const value = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop();
  return value ? decodeURIComponent(value) : null;
  // const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  // if (match) return match[2];
}

export function getCsrfTokenFromCookies() {
  const token = getCookie('XSRF-TOKEN');

  if (!token) return '';

  // most likely encoded padding symbol is found as '%3D'
  // if (token.slice(-3, -2) === '%') {
  //   return decodeURIComponent(token);
  // } else {
  return token;
  // }
}

export function isPlainObject(value: unknown): boolean {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const prototype = Object.getPrototypeOf(value);

  return (prototype === null || prototype === Object.prototype || Object.getPrototypeOf(prototype) === null)
    && !(Symbol.toStringTag in value) && !(Symbol.iterator in value);
}

export async function getResponseMessage(response: Response): Promise<string|null> {
  try {
    const { message } = await response.json();
    if (message) return message;

    return null;
  } catch (_) {
    return null;
  }
}
