<template>
  <DetailViewContainer>
    <template #title>
      <h3 v-text="sparte" />
    </template>
    <template #header>
      <div class="flex items-center">
        <div class="flex items-center">
          <CompanyIcon :id="contract.company.id" />

          <small
            class="ml-2.5 text-muted"
            v-text="contract.company.name"
          />
        </div>

        <DsButton
          v-if="contract.canBeDeleted && contract.userContractId"
          variant="danger"
          icon="trash"
          icon-variant="regular"
          class="ml-auto"
          data-testid="delete-button"
          @click="handleDelete(contract.userContractId)"
        >
          Vertrag löschen
        </DsButton>

        <DsModal
          ref="modal"
          title="Vertrag löschen"
          :variant="ModalVariant.Error"
          icon="trash"
          cancel-label="Abbrechen"
          confirm-label="OK"
        >
          Das Löschen des Vertrags kann nicht rückgängig gemacht werden. Sind Sie sicher, dass sie fortfahren möchten?
        </DsModal>
      </div>
    </template>

    <template #main>
      <ContractDetailsTable :contract="contract" />

      <DsAlert
        v-if="contract.note"
        type="info"
        label="Maklerbemerkung"
        class="mt-2.5"
      >
        {{ contract.note }}
      </DsAlert>
    </template>

    <template #extra>
      <ShallowCardCollapsible
        :title="`Dokumente (${contract.documents.length})`"
        :disabled="!hasDocuments"
        :open="hasDocuments && !isMobile"
        :max-height="'27rem'"
      >
        <ContractDocuments :documents="contract.documents" />
      </ShallowCardCollapsible>
    </template>
  </DetailViewContainer>
</template>

<script setup lang="ts">
import { DsAlert, DsButton, DsModal, ModalVariant } from '@demvsystems/design-components';
import { captureException } from '@sentry/vue';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { Contract } from '@/api/contracts/types';
import CompanyIcon from '@/application/components/CompanyIcon.vue';
import ShallowCardCollapsible from '@/application/components/ShallowCardCollapsible.vue';
import { useDetectMobile } from '@/application/composables/detectMobile';
import ContractDocuments from '@/contracts/components/ContractDocuments.vue';
import DetailViewContainer from '@/contracts/components/DetailViewContainer.vue';
import ContractDetailsTable from '@/contracts/components/ContractDetailsTable';
import { useContractStore } from '@/contracts/stores/contractStore';
import { spartenMapper } from '@/application/utils/sparteFormatter';
import { useRouter } from "vue-router";
import { call } from "@/api/lib/integration";
import { deleteContract } from "@/api/contracts/contractsApi";

type Props = {
  contract: Contract
}
const props = defineProps<Props>();

const modal: Ref<typeof DsModal | null> = ref(null);
const { isMobile } = useDetectMobile();

const sparte = computed(() => spartenMapper(props.contract.product));
const hasDocuments = computed(() => props.contract.documents.length > 0);
const router = useRouter()

function handleDelete(userContractId: number | null) {
  modal.value?.open({
    confirmed: () => {
      deleteUserContract(userContractId);
    },
  });
}

async function deleteUserContract(userContractId: number | null) {
  if (userContractId === null) return;

  await call(
    deleteContract({ userContractId }),
    async () => {
      useContractStore().removeUserContract(userContractId);
      await router.push('/meine-vertraege');
    },
    (error) => {
      captureException(error)
    },
  );
}
</script>
