import { useRouter } from "vue-router";

export function useGoBack() {

  const router = useRouter()

  async function goBack() {
    if (router.options.history.state.back === null) {
      await router.push('/');
      return
    }

    router.go(-1);
  }

  return {
    goBack
  }
}

