<template>
  <component
    :is="`h${props.level}`"
    class="text-gray-800"
  >
    <DsIcon
      v-if="icon"
      :name="icon.name"
      :size="icon.size"
      :variant="icon.variant"
      :class="icon.class"
    />
    {{ text }}
    <slot />
  </component>
</template>

<script setup lang="ts">

import type { IconVariant } from '@demvsystems/design-components';
import { DsIcon } from '@demvsystems/design-components';

interface Props {
  text: string;
  level?: '1' | '2' | '3' | '4' | '5' | '6';
  icon?: {
    name: string | string[],
    size: null | 'xs' | 'sm' | 'lg' | '2x' | '3x' | '5x' | '7x' | '10x',
    class: string,
  };
  variant?: IconVariant;
}

const props = withDefaults(defineProps<Props>(), {
  level: '1',
  icon: undefined,
});
</script>
