<script setup lang="ts">

</script>

<template>
<div class="modal-wrapper">
  <slot />
</div>
</template>

<style scoped lang="scss">
.modal-wrapper {
  margin-bottom: env(safe-area-inset-bottom, 0)
}
</style>
