<template>
  <SpartenWrapper
    image="images/bedarfsinfo/einkommenssicherung/header"
    :text="subtitle"
    alt="Glückliche Familie"
    :dokumente="data.bedarfsinfo.dokumente"
    :versorgungen="data.bedarfsinfo.versorgungen"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie gut ist mein Einkommen versichert?"
    versorgungen-description="Einkommensversicherung"
  >
    <Intro
      :einkommen="data.bedarfsinfo.einkommen"
      :is-freier-beruf="data.bedarfsinfo.isFreierBeruf"
      :is-selbststaendig="data.bedarfsinfo.isSelbststaendig"
    />

    <EsQuiz
      :sparten="data.sparten"
      :yes-no="data.yesNo"
    />

    <section class="section">
      <div class="container">
        <div class="row justify-center">
          <div class="col-12 col-lg-10">
            <div class="text-center pb-7">
              <h2>Wie unterscheiden sich die Versicherungen zur Einkommenssicherung?</h2>
            </div>
          </div>
        </div>
      </div>

      <Tabs
        has-small-buttons
        :initial-tab="data.pageTitle"
      >
        <DreadDiseaseTab name="Dread-Disease" />
        <ErwerbsunfaehigkeitTab name="Erwerbsunfähigkeit" />
        <GrundfaehigkeitTab name="Grundfähigkeit" />
        <MultiRiskTab name="Multi-Risk" />
      </Tabs>
    </section>

    <WarumJetzt />
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import WarumJetzt from '@/bedarf/components/WarumJetzt.vue';
import Intro from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/Intro.vue';
import DreadDiseaseTab from '@/bedarf/components/einkommenssicherung/erwerbsunfaefigkeit/DreadDiseaseTab.vue';
// eslint-disable-next-line max-len
import ErwerbsunfaehigkeitTab from '@/bedarf/components/einkommenssicherung/erwerbsunfaefigkeit/ErwerbsunfaehigkeitTab.vue';
import GrundfaehigkeitTab from '@/bedarf/components/einkommenssicherung/erwerbsunfaefigkeit/GrundfaehigkeitTab.vue';
import MultiRiskTab from '@/bedarf/components/einkommenssicherung/erwerbsunfaefigkeit/MultiRiskTab.vue';
import EsQuiz from '@/bedarf/components/einkommenssicherung/Quiz.vue';
import type { EinkommensicherungInfo } from '@/bedarf/types/bedarfsinfo';
import Tabs from '@/application/components/Tabs.vue';

interface Props {
  data: EinkommensicherungInfo
}

defineProps<Props>();

const subtitle = `Das Einkommen bildet die Basis für den Lebensstandard.
  Kann der Beruf aus gesundheitlichen Gründen nicht mehr ausgeübt werden, fangen die sozialen
  Sicherungssysteme nur einen Teil auf.
  Mit welchen Produkten Sie sich finanziell schützen können, erfahren Sie hier.`;
</script>
