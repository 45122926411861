import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

export const platform = Capacitor.getPlatform();
export const isNativePlatform = Capacitor.isNativePlatform();
export const isWebPlatform = !isNativePlatform;
export const appEnv = import.meta.env.VITE_APP_ENV;
export const viteMode = import.meta.env.MODE;
export const isLocalEnv = import.meta.env.VITE_APP_ENV === 'local';
export const isStagingEnv = import.meta.env.VITE_APP_ENV === 'staging';
export const isProductionEnv = import.meta.env.VITE_APP_ENV === 'production';
export const isDevelopmentBuild = import.meta.env.DEV;
export const isProductionBuild = import.meta.env.PROD;
export const appUrl = import.meta.env.VITE_APP_URL;

export async function getDeviceName() {
  const info = await Device.getInfo();

  if (info.name) return info.name;

  return isNativePlatform ? 'Native Mobile Device' : 'Web Device';
}

export async function onNative(callback: () => void|Promise<void>) {
  if (isNativePlatform) await callback();
}
