<template>
  <div class="custom-control custom-checkbox">
    <input
      v-bind="$attrs"
      :id="id"
      class="custom-control-input"
      type="checkbox"
      :checked="modelValue"
      @change="$emit('update:modelValue', !modelValue)"
    >
    <label
      :for="id"
      class="custom-control-label inline-block"
    >
      <slot>{{ label }}</slot>
    </label>
  </div>
</template>

<script lang="ts">
import { nanoid } from 'nanoid';
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  setup() {
    const id = nanoid();

    return {
      id,
    };
  },
});
</script>
