<template>

  <div class="relative text-center" v-if="loading">
    <DsIcon class="mr-2 text-blue-700" name="spinner-third" variant="duotone" spin size="2x"/>
  </div>

  <div  v-else-if="errorMsg">
  <DsAlert type="error" class="mb-5">{{ errorMsg }}</DsAlert>
    <DsButton
      variant="secondary"
      size="lg"
      type="submit"
      @click="emit('switch-to-password')"
      class="mt-6 w-full"
    >
      zum Login
    </DsButton>
  </div>

  <div v-else-if="hasTokenErrors" class="relative text-center">
    <DsAlert type="error" :label="errors.requestAccessToken[0]" />
    <DsButton
      variant="secondary"
      size="lg"
      type="submit"
      @click="emit('switch-to-password')"
      class="mt-6 w-full"
    >
      zum Login
    </DsButton>
  </div>

  <DsForm
    v-if="twoFactorMode && !hasTokenErrors && !loading"
    :validation-errors="errors"
    class="flex flex-col gap-2"
    @submit.prevent
  >
    <div>
       Für Ihre Anmeldung ist ein weiterer Faktor notwendig. {{ twoFactorLabels[twoFactorMode] }}
    </div>

    <DsFormGroup validation-name="answer" :label="twoFactorMode === TwoFactors.Birthdate ? 'Geburtsdatum' : 'Code/Recoverycode' ">
      <DsInput
        v-model="form.answer"
        type="default"
        size="lg"
        immediate
        autofocus
        :placeholder="twoFactorMode === TwoFactors.Birthdate? 'tt.mm.jjjj' : ''"
      />
    </DsFormGroup>

    <DsButton
      variant="primary"
      size="lg"
      type="submit"
      :handler="submit"
      class="mt-3 w-full"
    >
      Anmelden
    </DsButton>
  </DsForm>
</template>

<script setup lang="ts">
import { DsAlert, DsButton, DsForm, DsFormGroup, DsIcon, DsInput } from '@demvsystems/design-components';
import { useForm } from "@/api/lib/integration";
import { requestAccessTokenLogin } from "@/api/auth/authApi";
import { computed, onMounted, ref, watch } from "vue";
import { getDeviceName } from "@/application/utils/envInfo";
import { TwoFactors } from '@/api/auth/types';
import { useUserStore } from "@/user/store/userStore";

// 1.1 token is invalid, check if old login exists
// 1.2 token is valid, check 2fa
// 2. logging in

const twoFactorLabels = {
  [TwoFactors.Fortitfy]: 'Bitte geben Sie Ihren Authenticator Code ein.',
  [TwoFactors.Birthdate]: 'Bitte bestätigen Sie Ihr Geburtsdatum.',
}

const emit = defineEmits(['logging-in', 'switch-to-password']);

const twoFactorMode = ref('');
const loading = ref(true);

const { errors, form, submit, errorMsg } = useForm(
  requestAccessTokenLogin,
  {
    requestAccessToken: useUserStore().requestAccessToken,
    answer: undefined as undefined|string,
    deviceName: '',
  },
    (data) => {
      if (data.twoFactor) {
        twoFactorMode.value = data.twoFactor;
        return;
      }

      emit('logging-in', data.accessToken, data.clientSecret);
    },
);

const hasTokenErrors = computed(() => errors.value.requestAccessToken?.length > 0);

watch(hasTokenErrors, async (val) => {
  if (val){
    loading.value = true;
    if (await useUserStore().checkAuth()) {
      emit('logging-in');
    }
    loading.value = false;
  }
});


onMounted(async () => {
  form.value.deviceName = await getDeviceName();
  // initially send requestAccessToken to get 2FA challenge or login with accessToken
  await submit();
  loading.value = false;
  form.value.answer = '';
});

defineExpose({ form, errors })
</script>
