import { checkupRouteNames } from "@/application/router/checkup";
import type { NavigationHookAfter } from "vue-router";
import { isCheckupRoute } from "@/application/router/utils/utils";
import { checkupStatusGuard } from "@/application/router/guards/checkupStatusGuard";
import { trackEvent } from '@/application/utils/matomo';
import { TrackEventCategory } from '@/application/utils/matomo/events';

export const checkupAfterGuard: NavigationHookAfter = (to, from, failure) => {

  // this tracks if a user is leaving the checkup inside the vue app ('Absprung intern').
  // It is not tracking if the user is closing the tab or browser.
  if (isCheckupRoute(from, checkupRouteNames) && !to.path.includes('/check-up')) {

    trackEvent({
      category: TrackEventCategory.CHECKUP,
      action: from.name === 'Bestaetigung' ? 'Navigation nach Abschluss' : 'Absprung intern',
      name: `von: ${from.path} zu: ${to.path}`,
    });
  }

  if (isCheckupRoute(to, checkupRouteNames) && !checkupStatusGuard(to, from, failure)) {
    return false;
  }

  window.scrollTo(0, 0);

  return true;
}
