<template>
  <list
    :list="options"
    :after-classes="['pb-5', 'border-b', 'border-gray-200']"
    :before-classes="['pt-5']"
  >
    <template #default="{ item, index }">
      <div
        class="row flex"
        :class="{'items-center': !horizontalQuiz, 'md:justify-center': horizontalQuiz}"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="text-center md:text-left"
          :class="{'col-md-6': !horizontalQuiz, 'col-md-11': horizontalQuiz}"
          v-html="item.title"
        />
        <!-- eslint-enable vue/no-v-html -->
        <option-group
          v-bind="bindings(item)"
          class="mt-5"
          :class="{'col-md-6 md:mt-0': !horizontalQuiz, 'col-md-11 md:pl-4': horizontalQuiz}"
          @update:modelValue="payload => setValue(item.name || index, payload)"
        />
        <slot
          :class="{'col-md-6 md:mt-0': !horizontalQuiz, 'col-md-11 md:pl-4': horizontalQuiz}"
        />
      </div>
    </template>
  </list>
</template>

<script>
import { List } from '../List';
import OptionGroup from './OptionGroup.vue';
import { props } from './props';

export default {
  components: {
    List,
    OptionGroup,
  },

  props: {
    ...props,

    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ['update', 'input', 'update:modelValue'],

  methods: {
    bindings(item) {
      return {
        ...this.$props,
        ...item,
        modelValue: this.modelValue[item.name],
      };
    },

    setValue(key, value) {
      this.$emit('update', { key, value });
      this.$emit('update:modelValue', {
        ...this.modelValue,
        [key]: value,
      });
    },
  },
};
</script>
