import type { EventHint } from '@sentry/vue';
import { browserTracingIntegration, init } from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';
import { AbortError, FetchError, ValidationError } from "@/api/lib/errors";
import * as Sentry from "@sentry/browser";
import { appEnv, isLocalEnv, platform } from "@/application/utils/envInfo";

const statusCodesToIgnore = new Set([401]);

const isErrorToIgnore = (error: EventHint['originalException']): boolean => [
  error instanceof FetchError && error.status && statusCodesToIgnore.has(error.status),
  error instanceof AbortError,
  error instanceof ValidationError,
].some(Boolean);

export const initSentry = (app: App, router: Router | undefined): void => {
  if (isLocalEnv) return;

  init({
    app,
    dsn: __SENTRY_DSN__,
    environment: appEnv,
    integrations: [browserTracingIntegration({ router })],
    tracesSampleRate: 1.0,
    denyUrls: [
      'https://cloud.ccm19.de',
      /webkit-masked-url:\/\/hidden/i,
      /googleapis\.com/i,
    ],
    beforeSend: (event, { originalException }) => isErrorToIgnore(originalException) ? null : event,
  });

  Sentry.setTag('platform', platform);
};
