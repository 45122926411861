<script setup lang="ts">
import SvgVue from "@/application/components/SvgVue";
import { Finanzmanager } from "@/application/router/types/types";
import { isNativePlatform, isProductionEnv } from "@/application/utils/envInfo";
import { useBaseDataStore } from "@/user/store/baseDataStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const { maklername, maklertelefon, makleremail, maklerIsAgency, maklerOrAgencyUid, firmenname } = storeToRefs(useBaseDataStore());
const logoUrl = computed(() => {
  const maklerOrAgency = maklerIsAgency.value ? 'f' : 'm'
  const byUidPostFix = maklerOrAgencyUid.value !== null ? `/${maklerOrAgency}/${maklerOrAgencyUid.value}` : ''
  return `/api/makler/logo${byUidPostFix}`
})
</script>

<template>
    <Teleport to="#app-page-head">
      <div class="flex-none scontainer md:flex flex-row items-center lg:min-h-16 gap-y-2 gap-x-4">
        <div v-if="maklername" class="mt-4 w-full md:flex flex-row items-center">
          <div class="h-16 max-w-56 max-md:w-1/2 flex items-center md:mr-4">
            <img
              class="max-h-full max-w-full"
              :src="logoUrl"
              :alt="`Logo ${firmenname}`"
            />
          </div>
          <div>
            <div class="font-medium">{{ maklername }}</div>
            <div class="font-light" v-if="maklername !== firmenname">{{ firmenname }}</div>
          </div>
        </div>
      </div>
    </Teleport>

    <div class="scontainer mt-4 mb-8 grow grid max-lg:grid-rows-[auto_1fr] grid-cols-1 lg:grid-cols-2 xl:grid-cols-[600px_1fr] gap-6 lg:gap-14 xl:gap-24">
      <div class="md:mt-6 order-2 lg:order-1 space-y-8 lg:space-y-10 mx-auto">
        <RouterView />
      </div>

      <div class="order-1 lg:order-2">
        <div class="sticky top-4 lg:top-20">
          <div class="min-h-[30vh] md:min-h-[50vh] lg:min-h-[70vh] rounded-2xl welcome-image">
            <div class="p-3 top-0 rounded-2xl overflow-hidden inline-block" style="backdrop-filter: blur(20px)">
              <div class=" w-48 lg:w-56">
                <SvgVue icon="bannerHorizontal.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Teleport to="#app-page-footer">
      <div v-if="maklername" class="space-y-6 border-t border-gray-100">
        <div class="scontainer py-6 lg:py-10">
          <div class="grid grid-cols-1 lg:grid-cols-[1fr_3fr] items-end gap-x-20 gap-y-4">
            <div class="font-medium md:font-semibold text-lg md:text-2xl leading-tight">
              <span class="whitespace-nowrap">Sie haben Fragen?</span> <span class="whitespace-nowrap">Kontaktieren Sie {{ maklerIsAgency ? 'uns' : 'mich' }} gerne!</span>
            </div>
            <div class="flex flex-row flex-wrap gap-x-20 gap-y-3 md:gap-y-8">
              <div>
                <div class="max-sm:text-sm text-muted">Ansprechpartner</div>
                <div class="font-semibold md:text-lg">{{ maklername }}</div>
              </div>
              <div>
                <div class="max-sm:text-sm text-muted">Telefon</div>
                <div class="font-semibold md:text-lg">{{ maklertelefon }}</div>
              </div>
              <div>
                <div class="max-sm:text-sm text-muted">E-Mail</div>
                <div class="font-semibold md:text-lg">{{ makleremail }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isNativePlatform" class="hidden grow text-center px-4 py-10 border-t border-gray-100">
        <div class="mb-5 font-semibold text-gray-700">Auch im Appstore</div>
        <div class="inline-grid grid-cols-1 justify-items-center sm:grid-cols-2 gap-6">
          <div class="h-12">
            <SvgVue icon="appstore.svg"  class="w-auto h-full" alt="" />
          </div>
          <div class="h-12">
            <SvgVue icon="playstore.svg"  class="w-auto h-full" alt="" />
          </div>
        </div>
      </div>

      <div class="bg-gray-50 max-lg:text-center">
        <div class="scontainer space-x-4 md:space-x-10 text-xs md:text-sm py-3">
          <a v-if="isProductionEnv || true" href="#" onclick="CCM.openWidget();return false;" class="link">Cookies</a>
          <RouterLink :to="{ name: Finanzmanager.DATENSCHUTZ }" class="link">Datenschutz</RouterLink>
          <RouterLink :to="{ name: Finanzmanager.IMPRESSUM }" class="link">Impressum</RouterLink>
          <a href="https://www.demv.de" target="_blank" class="link">DEMV</a>
        </div>
      </div>
    </Teleport>
</template>

<style scoped lang="scss">
.welcome-image {
  background-size: cover;
  background-position: center center;
  background-image: url('../../../../assets/welcome.jpg');
}

.link {
  @apply text-gray-500 hover:text-dark transition-colors;
}

.scontainer {
  @apply max-w-[480px] md:max-w-[640px] lg:max-w-[1600px] w-full mx-auto px-4 lg:px-8;
}
</style>
