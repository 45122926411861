import type { Ref } from 'vue';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const siteBaseTitle = 'meine-finanzen.digital';

let isWatchingDocumentTitle = false;
let isWatchingRouteMeta = false;

const menuTitle = ref<string>('');
const documentTitle = ref<string>('');

function watchDocumentTitle() {
  if (!isWatchingDocumentTitle) {
    watch(documentTitle, (title) => {
      document.title = `${title} | ${siteBaseTitle}`;
    }, { immediate: true });
    isWatchingDocumentTitle = true;
  }
}

function watchRouteMeta() {
  if (!isWatchingRouteMeta) {
    const route = useRoute();
    watch(() => route.meta, (meta) => {
      if (meta.title) documentTitle.value = meta.title;
      menuTitle.value = meta.menuTitle ? meta.menuTitle : siteBaseTitle;
    }, { immediate: true });
    isWatchingRouteMeta = true;
  }
}

export function useTitles(): {
  documentTitle: Ref<string>,
  menuTitle: Ref<string>,
} {
  watchDocumentTitle();
  watchRouteMeta();
  return {
    documentTitle,
    menuTitle,
  };
}
