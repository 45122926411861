<template>
  <div>
    <h3 class="text-center md:text-left">
      Wie hoch ist meine Berufsunfähigkeitsrente nach Abzug von Steuern?
    </h3>

    <DsButton
      variant="secondary"
      size="sm"
      @click="showInfo = true"
    >
      Infos zur Berechnung
    </DsButton>
    <DsModal
      :show="showInfo"
      title="Infos zur Berechnung"
      cancel-only
      @cancel="showInfo = false"
    >
      <div class="flex flex-col text-left">
        <ul class="list-disc space-y-3 p-4">
          <li>Die Berechnung findet am Beispiel der
            <span class="font-bold">selbständigen Berufsunfähigkeitsversicherung (SBU)</span> statt.
          </li>
          <li>Es wird pauschal ein <span class="font-bold">Steuersatz von {{ steuersatz * 100 }} %</span> angenommen.
          </li>
          <li>Es werden <span class="font-bold">{{ format(steuerFreibetrag * 12) }} Steuerfreibetrag</span>
            berücksichtigt.
          </li>
          <li>Es sind Restlaufzeiten von einem Jahr bis 50 Jahren möglich.</li>
          <li>
            Krankenversicherungsbeiträge werden bei der Berechnung nicht berücksichtigt.
            Diese müssen separat abgezogen werden.
          </li>
        </ul>
      </div>
    </DsModal>

    <div class="flex flex-col md:flex-row justify-between md:items-center mt-7 md:mt-5">
      <span class="text-sm md:text-base mb-1">
        Restlaufzeit der BU-Versicherung bei Rentenbezugsbeginn:
      </span>
      <DsInput
        v-model="restlaufzeit"
        type="number"
        :min="1"
        :max="50"
        immediate
        suffix="Jahre"
        class="w-full md:w-48"
      />
    </div>

    <div class="flex flex-col md:flex-row md:items-center justify-between mt-7 md:mt-5">
      <span class="text-sm md:text-base mb-1">
        Vereinbarte Berufsunfähigkeitsrente:
      </span>
      <MoneyInput
        v-model="buRente"
        class="md:w-48"
      />
    </div>

    <div class="flex flex-col md:flex-row justify-between mt-7 md:mt-5">
      <span class="text-sm md:text-base mb-1">
        Berufsunfähigkeitsrente nach Steuer:
      </span>
      <span class="font-bold text-lg">
        {{ format(Math.round(buRenteNachSteuern)) }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DsButton, DsInput, DsModal } from '@demvsystems/design-components';
import { computed, ref } from 'vue';

import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import { format } from '@/application/utils/money';

const factorList = [
  { restlaufzeit: 1, factor: 0.00 },
  { restlaufzeit: 2, factor: 0.01 },
  { restlaufzeit: 3, factor: 0.02 },
  { restlaufzeit: 4, factor: 0.04 },
  { restlaufzeit: 5, factor: 0.05 },
  { restlaufzeit: 6, factor: 0.07 },
  { restlaufzeit: 7, factor: 0.08 },
  { restlaufzeit: 8, factor: 0.09 },
  { restlaufzeit: 9, factor: 0.10 },
  { restlaufzeit: 10, factor: 0.12 },
  { restlaufzeit: 11, factor: 0.13 },
  { restlaufzeit: 12, factor: 0.14 },
  { restlaufzeit: 13, factor: 0.15 },
  { restlaufzeit: 14, factor: 0.16 },
  { restlaufzeit: 15, factor: 0.16 },
  { restlaufzeit: 16, factor: 0.18 },
  { restlaufzeit: 17, factor: 0.18 },
  { restlaufzeit: 18, factor: 0.19 },
  { restlaufzeit: 19, factor: 0.20 },
  { restlaufzeit: 20, factor: 0.21 },
  { restlaufzeit: 21, factor: 0.22 },
  { restlaufzeit: 22, factor: 0.23 },
  { restlaufzeit: 23, factor: 0.24 },
  { restlaufzeit: 24, factor: 0.25 },
  { restlaufzeit: 25, factor: 0.26 },
  { restlaufzeit: 26, factor: 0.27 },
  { restlaufzeit: 27, factor: 0.28 },
  { restlaufzeit: 28, factor: 0.29 },
  { restlaufzeit: 29, factor: 0.30 },
  { restlaufzeit: 30, factor: 0.30 },
  { restlaufzeit: 31, factor: 0.31 },
  { restlaufzeit: 32, factor: 0.32 },
  { restlaufzeit: 33, factor: 0.33 },
  { restlaufzeit: 34, factor: 0.34 },
  { restlaufzeit: 35, factor: 0.35 },
  { restlaufzeit: 36, factor: 0.35 },
  { restlaufzeit: 37, factor: 0.36 },
  { restlaufzeit: 38, factor: 0.37 },
  { restlaufzeit: 39, factor: 0.38 },
  { restlaufzeit: 40, factor: 0.39 },
  { restlaufzeit: 41, factor: 0.39 },
  { restlaufzeit: 42, factor: 0.40 },
  { restlaufzeit: 43, factor: 0.41 },
  { restlaufzeit: 44, factor: 0.41 },
  { restlaufzeit: 45, factor: 0.42 },
  { restlaufzeit: 46, factor: 0.43 },
  { restlaufzeit: 47, factor: 0.43 },
  { restlaufzeit: 48, factor: 0.44 },
  { restlaufzeit: 49, factor: 0.45 },
  { restlaufzeit: 50, factor: 0.45 },
];

const steuerFreibetrag = 967;
const steuersatz = 0.2;

// restlaufzeit = Restlaufzeit der BU-Versicherung bei Rentenbezugsbeginn bis zur regulären Rente in Jahren
const restlaufzeit = ref(35);
const buRente = ref(2300);

const showInfo = ref(false);

const factor = computed(() => {
  if (restlaufzeit.value < 1) {
    return factorList[0].factor;
  }

  return factorList.find((item) => item.restlaufzeit === restlaufzeit.value)?.factor ?? 0.45;
});

const buRenteNachSteuern = computed(() => {

  // versteuerbarer Betrag ergibt sich aus der BU Rente multipliziert mit dem Factor aus der factorList
  const versteuerbarerBetrag = buRente.value * factor.value;

  // wenn der versteuerbare Betrag unter dem Steuerfreibetrag liegt, fallen keine Steuern an.
  if (versteuerbarerBetrag <= steuerFreibetrag) {
    return buRente.value
  }

  const steuern = (versteuerbarerBetrag - steuerFreibetrag) * steuersatz;

  return buRente.value - steuern;
});
</script>
