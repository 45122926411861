import { SecureStorage } from '@aparajita/capacitor-secure-storage'

SecureStorage.setSynchronize(false).then();

export const SecretNames = {
  AccessToken: 'accessToken',
  ClientSecret: 'clientSecret',
} as const;

type Secrets = typeof SecretNames[keyof typeof SecretNames];

export async function setSecret(name: Secrets, value: string): Promise<void> {
  await SecureStorage.setItem(name, value);
}

export async function getSecret(name: Secrets): Promise<string|null> {
  return await SecureStorage.getItem(name) as string|null;
}

export async function removeSecret(name: Secrets): Promise<void> {
  return await SecureStorage.removeItem(name);
}
