<template>
  <div
    v-if="firmenname && maklername"
    class="flex flex-col w-full md:pr-2 md:border-r-2"
    :class="hasLongFirmenname ? 'md:max-w-[260px] lg:max-w-xs' : 'md:w-fit'"
  >
    <span
      v-if="!maklerIsAgency && !isMobile"
      class="font-semibold min-w-0 truncate text-gray-900"
      :title="maklername"
    >
      {{ maklername }}
    </span>
    <span
      v-if="isMobile"
      class="font-semibold min-w-0 truncate text-gray-900"
    >
      {{ menuTitle }}
    </span>
    <span
      v-if="firmenname !== maklername"
      class="truncate leading-5"
      :class="hasLongFirmenname ? 'text-xs tracking-tight' : 'text-sm'"
      :title="firmenname"
    >
      {{ firmenname }}
    </span>
  </div>

  <template v-if="!isMobile">
    <DesktopMenu />
    <DesktopMenuDropdown />
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useDetectMobile } from '@/application/composables/detectMobile';
import { useTitles } from '@/application/composables/title';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import DesktopMenu from "@/application/menu/components/DesktopMenu.vue";
import DesktopMenuDropdown from "@/application/menu/components/DesktopMenuDropdown.vue";

const { firmenname, maklername, maklerIsAgency } = storeToRefs(useBaseDataStore());
const { menuTitle } = useTitles();
const { isMobile } = useDetectMobile();

const hasLongFirmenname = computed(() => firmenname.value && firmenname.value.length > 39);
</script>
