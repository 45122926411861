<template>
  <div>
    <LoadSpinner v-show="loading" />

    <div v-if="!isTwoFactorEnabled">
      Zusätzlich zur Anmeldung mit E-Mail und Passwort können Sie Ihren Account mit
      einem sog. zweiten Faktor absichern (ähnlich wie eine TAN beim Online-Banking).
      Mehr Informationen finden Sie <RouterLink
        :to="{
          name: Finanzmanager.HINWEISE_SHOW,
          params: {id: 1 }
        }"
      >
        <strong>hier</strong>
      </RouterLink> .
    </div>

    <div v-if="isTwoFactorEnabled && !qrCode">
      Sie haben die Zwei-Faktor-Authentifizierung für ihren Account eingerichtet.
    </div>

    <div
      v-if="qrCode && !isTwoFactorConfirmed"
      class="text-center"
    >
      <div class="alert alert-warning">
        Beachten Sie, dass Sie sich bei Verlust des zweiten Faktors dauerhaft aus Ihrem
        Account aussperren können. Daher ist es wichtig, dass Sie sich die im nächsten
        Schritt angezeigten Wiederherstellungscodes notieren.
      </div>

      <div>
        Scannen Sie den folgenden QR-Code mit einer Authenticator-App (z.B. Google
        Authenticator, Authy)
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mt-2.5 inline-block"
        v-html="qrCode"
      />
      <!-- eslint-enable vue/no-v-html -->

      <div
        v-if="twoFactorSecret"
        class="my-2.5"
      >
        Falls sie den QR-Code nicht scannen können, geben Sie den folgenden Code in Ihre
        Authenticator-App ein:
        <div>
          <strong>{{ twoFactorSecret }}</strong>
        </div>
      </div>

      <div>
        <ConfirmTwoFactorAuthentication @confirmed="onTwoFactorConfirm" />
      </div>
    </div>

    <div
      v-if="isTwoFactorConfirmed && recoveryCodes.length > 0"
      class="alert alert-warning mt-2.5"
    >
      Notieren Sie sich bitte <strong>alle</strong> der folgenden Wiederherstellungscodes, um im Falle des
      Verlusts Ihres Mobiltelefons weiterhin Zugriff auf Ihren Account zu erhalten:
      <div class="flex justify-center">
        <div class="my-5">
          <table class="table table-striped text-monospace text-muted mb-0">
            <tbody>
              <tr
                v-for="code in recoveryCodes"
                :key="code"
              >
                <td
                  class="align-middle p-1.5 border-0"
                  style="border-top: none !important"
                  v-text="code"
                />
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex flex-col md:flex-row md:justify-center items-center">
        <DsButton
          v-if="windowReference.isSecureContext"
          data-testid="2fa-copy-codes"
          variant="outline"
          :icon="codesCopied ? 'check' : 'copy'"
          @click="copyRecoveryCodes"
        >
          Codes in Zwischenablage kopieren
        </DsButton>

        <DsButton
          data-testid="2fa-download-codes"
          variant="outline"
          icon="download"
          :handler="downloadPdf"
        >
          Als PDF herunterladen
        </DsButton>
      </div>
    </div>

    <div
      v-if="!showPasswordConfirm"
      class="text-right mt-5"
    >
      <DsButton
        data-testid="2fa-action-button"
        size="lg"
        @click="onClick"
      >
        {{ buttonText }}
      </DsButton>
    </div>

    <ConfirmPassword
      v-if="!isPasswordConfirmed && showPasswordConfirm"
      class="w-full"
      @confirmed="onPasswordConfirm"
    />
  </div>
</template>

<script setup lang="ts">
import { DsButton } from '@demvsystems/design-components';
import { computed, ref } from 'vue';

import ConfirmPassword from '@/authentication/components/ConfirmPassword.vue';
import ConfirmTwoFactorAuthentication from '@/authentication/components/ConfirmTwoFactorAuthentication.vue';
import LoadSpinner from '@/application/components/LoadSpinner.vue';
import { Finanzmanager } from "@/application/router/types/types";
import {
  getConfirmedPasswordStatus,
  disableTwoFactor,
  getTwoFactorRecoveryCodes,
  enableTwoFactor,
  getTwoFactorQrCode,
  recoveryCodesPdfUrl,
} from "@/api/auth/authApi";
import { call } from "@/api/lib/integration";
import { useFileDownload } from "@/application/composables/fileDownload";

const props = defineProps({
  twoFactorEnabled: {
    type: Boolean,
    default: false,
    required: true,
  },
});

const qrCode = ref('');
const twoFactorSecret = ref<string | null>('');
const recoveryCodes = ref<string[]>([]);
const isTwoFactorEnabled = ref(props.twoFactorEnabled);
const isPasswordConfirmed = ref(false);
const isTwoFactorConfirmed = ref(false);
const needsTwoFactorConfirmation = ref(false);
const showPasswordConfirm = ref(false);
const loading = ref(false);
const windowReference = window;
const codesCopied = ref(false);

const emit = defineEmits(['stateChange']);
const { downloadApiFile } = useFileDownload();

const buttonText = computed(() => {
  if (!isTwoFactorEnabled.value) return 'Aktivieren';
  if (isTwoFactorEnabled.value && needsTwoFactorConfirmation.value && !isTwoFactorConfirmed.value) return 'Abbrechen';
  return 'Deaktivieren';
});

async function enableTwoFactorAuthentication() {
  loading.value = true;
  await call(
    enableTwoFactor(),
    async () => {
      await call(
        getTwoFactorQrCode(),
        async ({ svg, url }) => {
          const secret = (new URL(url)).searchParams.get('secret');
          await call(
            getTwoFactorRecoveryCodes(),
            (recCodes) => {
              qrCode.value = svg;
              recoveryCodes.value = recCodes;
              twoFactorSecret.value = secret;
              isTwoFactorEnabled.value = true;
              needsTwoFactorConfirmation.value = true;
            }
          )
        },
      );
    },
  );
  loading.value = false;
}

async function disableTwoFactorAuthentication() {
  loading.value = true;
  await call(
    disableTwoFactor(),
    () => {
      isTwoFactorEnabled.value = false;
      isTwoFactorConfirmed.value = false;
      qrCode.value = '';
      recoveryCodes.value = [];
      emit('stateChange', isTwoFactorEnabled.value);
    }
  );
  loading.value = false;
}

async function requestConfirmedStatus() {
  await call(
    getConfirmedPasswordStatus(),
    (data) => { isPasswordConfirmed.value = data?.confirmed ?? false }
  );
}

async function onClick() {
  if (!isPasswordConfirmed.value) {
    showPasswordConfirm.value = true;
    return;
  }
  toggle2fa();
}

function onPasswordConfirm() {
  showPasswordConfirm.value = false;
  isPasswordConfirmed.value = true;
  toggle2fa();
}

function onTwoFactorConfirm() {
  needsTwoFactorConfirmation.value = false;
  isTwoFactorConfirmed.value = true;
  emit('stateChange', isTwoFactorEnabled.value);
}

function toggle2fa() {
  if (!isTwoFactorEnabled.value) enableTwoFactorAuthentication();
  else disableTwoFactorAuthentication();
}

async function copyRecoveryCodes() {
  const codes = recoveryCodes.value.join('\n');
  await navigator.clipboard.writeText(codes);
  codesCopied.value = true;
  setTimeout(() => { codesCopied.value = false; }, 3000);
}

function downloadPdf() {
  return downloadApiFile(recoveryCodesPdfUrl, 'Wiederherstellungscodes.pdf');
}

requestConfirmedStatus();
</script>
