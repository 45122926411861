<template>
  <div class="bg-light">
    <section class="md:container py-7 flex flex-row justify-center">
      <div class="flex flex-col md:w-10/12 lg:w-8/12 md:p-4 gap-2">
        <h1 class="hidden md:block text-center">
          {{ isCompany ? 'Unser Account' : 'Mein Account' }}
        </h1>

        <div class="md:hidden flex flex-row items-center justify-between mx-4 mt-5">
          <span class="font-semibold text-lg">Zwei-Faktor-Authentifizierung</span>
          <DsBadge :type="isTwoFactorEnabled ? 'success' : 'default'">
            {{ isTwoFactorEnabled ? 'aktiviert' : 'deaktiviert' }}
          </DsBadge>
        </div>
        <DsCard class="mb-10">
          <Heading
            level="5"
            text="Zwei-Faktor-Authentifizierung"
            :icon="{name: 'shield', variant: 'regular', class: 'text-secondary mr-2'}"
            class="hidden md:block border-b border-gray-200 pb-2 mb-6 font-semibold flex"
          >
            <DsBadge
              :type="isTwoFactorEnabled ? 'success' : 'default'"
              class="inline-block float-right"
            >
              {{ isTwoFactorEnabled ? 'aktiviert' : 'deaktiviert' }}
            </DsBadge>
          </Heading>

          <TwoFactorForm
            :two-factor-enabled="isTwoFactorEnabled"
            @state-change="isTwoFactorEnabled = $event"
          />
        </DsCard>

        <span class="md:hidden mx-4 font-semibold text-lg">Zugangsdaten ändern</span>
        <DsCard class="mb-10">
          <Heading
            level="5"
            text="Zugangsdaten ändern"
            :icon="{name: 'key', variant: 'regular', class: 'text-secondary mr-2'}"
            class="hidden md:block border-b border-gray-200 pb-2 mb-6 font-semibold"
          />
          <p>
            Falls Sie Ihr Passwort ändern möchten, oder noch kein Passwort gesetzt
            haben, geben Sie ein neues Passwort ein und bestätigen dies.
          </p>

          <TheChangePasswordForm :login-email="kundeEmail ?? ''" />
        </DsCard>

        <span class="md:hidden mx-4 font-semibold text-lg">Account löschen</span>
        <DsCard class="mb-10">
          <Heading
            level="5"
            text="Account löschen"
            :icon="{name: 'trash', variant: 'regular', class: 'text-secondary mr-2'}"
            class="hidden md:block border-b border-gray-200 pb-2 mb-6 font-semibold"
          />
          <p>
            Falls Sie ihren Account vollständig und unwiderruflich löschen möchten,
            bestätigen Sie bitte dies und klicken im Anschluss auf "Account löschen".
            Bei Bedarf kann Ihr Berater jederzeit einen neuen Account anlegen.
          </p>

          <TheDeleteForm />
        </DsCard>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { DsBadge, DsCard } from '@demvsystems/design-components';
import Heading from '@/application/components/Heading.vue';

import { storeToRefs } from "pinia";
import { useBaseDataStore } from "@/user/store/baseDataStore";
import TheDeleteForm from "@/user/components/TheDeleteForm.vue";
import TwoFactorForm from "@/user/components/TwoFactorForm.vue";
import TheChangePasswordForm from "@/user/components/TheChangePasswordForm.vue";

const { kundeEmail, twoFactorEnabled, isCompany } = storeToRefs(useBaseDataStore());

const isTwoFactorEnabled = ref(twoFactorEnabled.value)
</script>
