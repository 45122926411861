<script setup lang="ts">
import PageHeader from "@/application/components/PageHeader.vue";
import PageFooter from '@/application/components/PageFooter.vue';
import PasswordInformationBanner from "@/authentication/components/PasswordInformationBanner.vue";
import OnboardingModal from "@/application/components/OnboardingModal.vue";
import { useFirstLogin } from '@/application/utils/firstLogin';
import { useRoute } from "vue-router";
import MenuMobile from "@/application/menu/components/MenuMobile.vue";
import { storeToRefs } from "pinia";
import { useBaseDataStore } from "@/user/store/baseDataStore";
import MediaPreviewMount from "@/application/components/MediaPreview/MediaPreviewMount.vue";

const route = useRoute();
const isLoginRoute = route.path === '/login'

const { show, close } = useFirstLogin();
const {
  isAuthenticated,
} = storeToRefs(useBaseDataStore());
</script>

<template>
  <Teleport to="#app-header">
    <PageHeader />
  </Teleport>
  <Teleport to="#app-page-head">
    <PasswordInformationBanner v-if="isAuthenticated && !isLoginRoute" />
  </Teleport>
  <router-view />
  <Teleport to="#app-page-footer">
    <PageFooter />
  </Teleport>
  <Teleport to="#app-footer">
    <MenuMobile />
  </Teleport>
  <Teleport to="#app-overlay">
    <MediaPreviewMount />
  </Teleport>
  <OnboardingModal
      :show="show"
      @close="close"
  />
</template>
